.loading-ring {
    position: absolute;
    z-index: 10;
    top:.35vw;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: scale(1.06) translateY(-.05vw);
    display: flex;
    justify-content: center;
    align-items: center;
    left: .5vw;

    svg {
        padding: 0;
        margin: 0;
        width: 20px;
        height: 20px;
    }
}