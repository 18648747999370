.Monitoramento{
    margin: 0;
    list-style: none;
    border-radius: 6px;
    border: 1px solid #43454D;
    background: #373940;
    color: #FFF;
    text-align: left;
    font-size: 14px;
    line-height: 28px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
    display: inline-block;
    width: 100%;
    padding: 0 10px;
    span{
        color: #727685;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        float: left;
        padding: 10px 0 10px 0;
    }
    ul{
        float: right;
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            color: #FFF;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            float: left;
            padding: 6px 4px 2px 2px;
            border-radius: 4px;
            margin-right: 4px;
            margin: 4px 0;
        }
        
    }
}
.accordion{
    width: 100%;
    .accordion-item{
        background: none !important;
        border: none;
        width: 100%;
        .accordion-button{
            padding: 0;
            background: none;
            width: 100%;
            border: none;
            box-shadow: none;
            position: relative;
            margin-left: 0;
            h4{
                width: 100%;
            }
            svg{
                position: absolute;
                top: 5px;
                right: 5px;
                color: #FFF;
                font-size: 20px;
            }
            &::after{
                display: none;
            }
        }
        .accordion-body{
            padding: 0;
            border: none;
        }
    }

}
.ModalLead{
    .modal-dialog {
        min-width: 920px;
        .boxResumo {
            border:none;
            background: none;
            padding: 0px 0px 20px;
            list-style: none;
            li{
                width: 49.5%;
                display: inline-block;
                margin-right: 1%;
                color: #FFF;
                &:nth-child(even){
                    margin-right: 0;
                }
            }
            ul{
                padding: 0;
                margin-bottom: 0;
                display: inline-block;
                width: 100%;
                background: #373940;
                margin-bottom: 5px;
                li {
                    padding: 8px 15px;
                    color: #A7A7A7;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    display: inline-block;
                    margin-bottom: 0;
                    width: calc(100% - 128px);
                    &:nth-child(2) {
                        text-align: right;
                        color: #A7A7A7;
                        width: 120px;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.72px;
                        text-transform: uppercase;
                        font-family: var(--FontTertiary);
                    }
                }
            }
        }
        .boxValor {
            background: #373940;
            padding: 10px 15px;
            text-align: right;
            h2 {
                color: #FFFFFF;
                font-size: 22px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 1.32px;
                text-transform: uppercase;
                width: 100%;
                display: inline-block;
                font-family: var(--FontTertiary);
                span {
                    color: #828282;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    letter-spacing: 0.672px;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    width: 100%;
                    display: block;
                }
            }
        }
    }
    .modal-content {
        .modal-body  {
            h4 {
                font-size: 14px;
            }
            h2 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-bottom: 10px;
                position: absolute;
                right: 20px;
                top: 10px;
                margin-bottom: 20px;
                margin-top: 10px;
                font-family: var(--FontTertiary);
                padding-right: 10px;

                span{
                    color: #A7A7A7;
                    font-size: 12px;
                    font-style: normal;
                    margin-right: 5px;
                }
            }
            label{
                font-size: 9px;
            }
            .boxModalLead{
                padding: 14px 30px;
                background: #32343A;
                border-radius: 16px;
                position: relative;
                margin-top: -60px;
                margin-bottom: 20px;
                min-height: 351px;
                &.end{
                    margin-top: 0;
                    min-height: inherit;
                    .boxResumo{
                        margin-bottom: 0;
                    }
                }
                .btnStart{
                    margin-left: 0;
                }
                .btnEnd{
                    margin-right: 0;
                }
                .Monitoramento{
                    text-align: left;
                    padding-bottom: 6px;
                    &.origem{
                        min-height: 32px;
                        margin-bottom: 2px !important;
                        ul{
                            float: right;
                            width: calc(100% - 50px);
                        }
                        li{
                            background: none;
                            padding: 4px 6px 0 0;
                            &:last-child{
                                display: inline-block !important;
                            }
                        }
                    }
                    &.color{
                        background: #26272C;
                    }
                    span{
                        text-transform: uppercase;
                        font-size: 10px;
                        margin-right: 6px;
                    }
                    ul{
                        float: left;
                    }
                    li{
                        border-radius: 12px;
                        background: #474A53;
                        padding: 2px 16px;
                        &.setas{
                            background: #38383a !important;
                            padding: 2px;
                            line-height: 10px;
                            margin: 5px;
                        }
      
                    }
                }
            }
            label {
                margin-bottom: 0;
            }
            input:not([type=checkbox]) {
                line-height: 20px !important;
                color: #9ca0ae;
                font-size: 12px;
                margin-bottom: 8px;
                font-family: var(--FontTertiary);
            }
            textarea{
                font-family: var(--FontTertiary);
                color: #9B9B9B !important;
                font-weight: 400;
                    font-size: 12px;
                &::placeholder {
                    font-weight: 400;
                    font-size: 11px;
                    color: #9B9B9B !important;
                }
            }
            .btnFilter{
                border-radius: 6px;
                text-align: left;
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding: 14px 12px;
                margin: 0 1%;
                width: 32%;
                line-height:24px;
                margin-bottom: 6px;
                border-radius: 6px;
                border: 1px solid #4E515C;
                background: #32343A;
                font-family: var(--FontTertiary);
                &.activo{
                    border: 1px solid #373940;
                    background: #26272C;
                }
                span{
                    border-radius: 12px;
                    color: var(--whiteColor);
                    background: #682BDE;
                    padding: 2px 4px;
                    font-weight: 500;
                    line-height: 18px;
                    float: right;
                }
            }
            .flutu{
                position: absolute;
                top: -140px;
                right: 30px;
                background: #48A662;
                position: absolute;
                width: 40px;
                height: 40px;
                border-radius: 100px;
                text-align: center;
                svg{
                    color: #FFF;
                    font-size: 22px;
                    margin-top: 8px;
                }
            }
        }
        .modal-header{
            position: relative;
            overflow: hidden;
            border-radius: 5px 5px 0 0;
            background: linear-gradient(90deg, #8A50FD 0%, #7038E0 100%);
            padding: 33px 30px !important;
            border-bottom: none;
            margin-bottom: 30px;
            svg {
                font-size: 27px;
                color: #FFFFFF;
                position: relative;
                right: 2px;
            }
            span{               
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: rgba(255, 255, 255, .2);
                font-weight: 900;
                text-transform: uppercase;
                font-size: 50px;
                color: transparent;
                line-height: 40px;
                position: absolute;
                right: 0;
                top: 10px;
                font-style: italic;
                
            }
        }
    }
}

@media (max-width: 768px) {
    .ModalLead {
        .modal-dialog {
            min-width: 360px;
        }
        .modal-content {
            .modal-body {
                padding: 10px 20px 30px 20px;
                .boxModalLead {
                    min-height: auto;
                    margin-top: 0;
                    padding: 14px 20px;
                }
                .btnFilter {
                    min-height: 80px;
                }
                .navegacao{
                    display: none;
                }
                .flutu {
                    top: -50px;
                    right: 20px;
                }
                h2 {
                    font-size: 18px !important;
                    position: relative !important;
                    right: 0 !important;
                    top: 0 !important;
                    border-bottom: 1px solid #373940;
                    text-align: right;
                }
            }
        }
    }
}
  