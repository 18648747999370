.ModalCategoriasNew{
    .modal-content {
        .react-datepicker-wrapper {
            width: 100%;
        }

        .form-switch {
            margin-top: 10px;
            margin-bottom: 10px;

            .form-check-input:checked,
            .form-check-input:checked:focus {
                background-color: var(--primaryColor);
                border-color: var(--primaryColor);
            }

            .form-check-label {
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
            }
        }

    }
}

.ModalCategoriasNew {
    .DatePicker {

        .react-datepicker__input-container{
              display: flex;
            align-items: center;
            justify-content: center;
        }

    
        svg {
            //padding: 14px;
            left: 0;
            fill: #fff;
        }
        // input{
        //     padding-left: 30px;
        // }

    }




}