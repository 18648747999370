.Avatar.loading-ring {
    background-color: #502c96;
    position: absolute;
    z-index: 10;
    top:.35vw;
    width: 154px;
    height: 154px;
    border-radius: 50%;
    transform: scale(1.06) translateY(-.05vw);
    display: flex;
    justify-content: center;
    align-items: center;
    left: .3vw;

    svg {
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
    }
}