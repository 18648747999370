// MyArticles.scss

$font-family: 'Raleway', sans-serif;
$primary-color: #C8ECFC;
$secondary-color: #292D4D;


.Veiculos {
    .HeaderVeiculos{
        position: relative;
        padding-bottom: 20px;
        border-bottom: 1px solid #343434;
        i{
           color: #763EE7;
           font-size: 32px;
           float: left;
           margin-right: 10px;
           line-height: 0;
        }
        h2{
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; 
            line-height: 22px;
            span{
                color: #727685;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    
    }
    .actionsVeiculos{
        text-align: right;
    }
    .listVeiculos{
        border-radius: 8px;
        background: linear-gradient(180deg, #222328 0%, #202124 100%);
        padding: 30px;

        .AddVeiculo{
            border-radius: 8px;
            border: 1px solid #2C2D30;
            background: #26272C;
            height: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 275px;
            .Add{
                width: 59px;
                height: 59px;
                font-size: 36px;
            }
        }
    }
}
@media (max-width: 768px) {
    .Veiculos {
        .listVeiculos {
            padding: 12px 16px !important;
            .ItemBanner {
                padding: 10px 6px;
                margin-bottom: 10px;
                .imagem {
                    height: 60px;
                    margin-bottom: 10px;
                    overflow: hidden;
                }
                h2 {
                    font-size: 11px;
                    height: 26px;
                }
                h6 {
                    font-size: 9px;
                }
                h3 {
                    font-size: 14px;
                    width: 100%;
                }
                .acoes {
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                }
                .Btns.ButtonSecondary, .Btns.ButtonPrimary {
                    padding: 8px 10px;
                    font-size: 10px;
                    margin: 6px 0 0 0;
                    width: 100%;
                }
                hr {
                    margin: 8px 0;
                }
                .ButtonIcon {
                    margin-left: 2px;
                    margin-right: 2px;
                    float: inherit;
                }
                > a{
                    display: none;
                }
                span {
                    font-size: 8px;
                    left: 4px !important;
                    top: 4px !important;
                }
            }
            .row{
                > div {
                    padding: 0 5px;
                }
            }
            .AddVeiculo{
                display: none;
            }
        }
    }
}