.ItemDestaque{
    border-radius: 8px;
    border: 1px solid #2C2D30;
    background: #26272C;
    padding: 16px 20px;
    margin-bottom: 30px;
    &:hover{
        border-radius: 8px;
        border: 1px solid #2C2D30;
        background: linear-gradient(180deg, #373940 0%, #373940 100%);
        box-shadow: -1px 2px 9.8px 0px rgba(29, 30, 34, 0.26);
        h4{
            background: #2E3036;
        }
    }
    .imagem{

        .thumbnail{
            width: 100%;
            object-fit: cover;
            max-height: 120px;
        }

        width: 100%;
        height: 120px;
        background: #cdcdcd;
        border-radius: 5px;
        margin-bottom: 20px;
        position: relative;
        span{
            border-radius: 41.85px;
            border: 1px solid #167230;
            background: #8FD1A2; 
            width: auto;
            display: inline-block;
            padding: 2px 8px;
            color: #167230;
            left: 10px;
            top: 10px;
            position: absolute;
            i{
                width: 8px;
                height: 8px;
                flex-shrink: 0;
                background: #278F44;
                display: inline-block;
                border-radius: 20px;
                margin-right: 4px;
                position: relative;
                top: 0px;
            }
            &.danger{
                border: 1px solid #813933;
                background: var(--dangerColor);
                color: #813933;
                i{
                    background: #813933;
                }
            }
            &.warning{
                border: 1px solid #785E00;
                background: var(--warningColor);
                color: #785E00;
                i{
                    background: #785E00;
                }
            }
        }
    }
    h2{
        color: #D9D9D9;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-size: 16px;
        height: 37px;
    }
    h6{
        color: #5C5D66;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;              
    }
    span{
        color: #5C5D66;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;  
        width: 100%;    
        display: block;   
        text-transform: uppercase;  
        margin-bottom: 4px;    
    }
    hr{
        border-bottom: 1px solid #36373E;
        width: 1005;
    }
    h3{
        color: #D9D9D9;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; 
        width: 50%;
        display: inline-block;
    }
    h4{
        color: #B1B7D1;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;  
        border-radius: 45px;
        border: 1px solid #373940;
        background: #232428;
        padding: 4px 10px;
        width: 50%;
        display: inline-block;
        text-align: center;
        i{
            font-size: 16px;
        }
    }
    button{
        margin-top: 16px;
    }
    .acoes{
        display: flex;
        .ButtonPrimary{
            &:first-child {
                flex-grow: 1;
            }
        }

    }
    .ButtonIcon{
        margin-left: 10px;
        float: right;
    }
}