@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
.pdf.consignacao{
    font-family: "Inter", sans-serif;
    width: 1082px;
    margin: auto;
    .capa{
        background: #000 url(img/logoDream.png) no-repeat center;
        height: 1530px;
        width: 1082px;
        h6{
            color: #5F5F5F;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px;
            text-transform: uppercase;
            padding-top: 1000px;
        }
    }
    .paginas{
        height: 1530px;
        width: 1082px;
        padding: 60px;
        position: relative;
        background: #FFF;
        border-bottom: 10px solid #000;
        .assinatura{
            bottom: 150px;
            position: absolute;
            width: 40%;
            border-top: 1px solid #000;
            .parte1{
                color: #000;
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
            }
            .parte2{
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
            }
            &.cliente{
                right: 10%;
            }
        }
        .logo{
            margin-bottom: 30px;
            width: 140px;
        }
        h2{
            padding: 10px 0 !important;
            width: 100%;
            color: #000;
            text-align: left;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 30px;
            border: none !important;
        }
        p{
            font-size: 18px;
        }
    }  
}
 
.barPrint {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background:  linear-gradient(180deg, #1D1E20 0%, #131415 100%);
    padding: 20px;
    button{
        margin: 0 10px;
    }

}
@media (max-width: 768px) {
    html {
        -webkit-text-size-adjust: 100%;
    }
    .capa, .paginas, .verso {
        zoom: 0.3; /* Primeira tentativa com zoom */
        margin: auto;
        overflow: hidden;
    }

    .pdf {
        width: 100%;
        font-size: 12px;
    }
}
@supports (-webkit-touch-callout: none) {
    /* Estilos aplicados apenas em iOS */
    .capa {
        h6 {
            font-size: 12px;
            margin-top: 10px;
        }
    }
    .paginas {
        p {
            font-size: 12px;
        }
        ul {
            li {
                font-size: 8px;
            }
        }
        .data {
            h3 {
                width: 80%;
                margin-bottom: 20px;
                font-size: 10px;
            }
            p {
                font-size: 8px;
            }
        }
        h1 {
            font-size: 14px;
            margin-bottom: 2px;
            strong{
                font-size: 18px;
            }
        }
        h6{
            font-size: 8px;
            padding-top: 0;
        }
        h2 {
            padding: 5px 10px;
            width: 100%;
            border-left: 4px solid #5AB24C;
            font-size: 7px;
            margin-bottom: 20px;
        }
        .campos {
            .label, .campo {
                font-size: 7px;
            }
        }
        .valores{
            h4 {
                font-size: 6px;
            }
            h3 {
                font-size: 10px;
                margin-bottom: 0;
                span{
                    font-size: 6px;
                }
            }
            span{
                font-size: 6px;
            }
            hr{
                margin: 2px 0;
                padding: 0;
            }
        }
        h4 {
            font-size: 6px;
        }
        h3 {
            font-size: 10px;
        }
        h5 {
            font-size: 7px;
        }
        ul{
            margin: 0;
            padding: 0 0 0 40px;
            ul{
                margin: 0;
                padding: 0;
                li{
                    font-size: 6px;
                }
            }
        }
        .protecao{
            transform: scale(0.44);
            transform-origin: 0 0;
        }
        &.timbrado2 {
            p {
                font-size: 7px;
            }
            ul {
                li {
                    font-size: 6px;
                }
            }
            .ajuste-mobile-h5{
                margin-top: -720px;
            }
            h5{
                margin-bottom: 0;
            }
        }
        .assinatura {
            .parte1 {
                font-size: 6px;
            }
            .parte2 {
                font-size: 8px;
            }
        }
    }
    .verso{
        p {
            font-size: 9px;
        }
        h1 {
            font-size: 10px;
            padding-top: 1300px;
        }
    } 
}

@media print {
    body {
        margin: 0; /* Remove as margens do body */
    }
    .capa, .paginas, .verso {
        width: 1082px;  /* Define a largura original */
        height: 1530px; /* Define a altura original */
        zoom: 1;        /* Remove qualquer escala aplicada */
        margin: 0;
        page-break-after: always;
        width: 100vw; /* Usa 100% da largura da viewport */
        height: 100vh; /* Usa 100% da altura da viewport */
        box-sizing: border-box; /* Certifica que padding e border são incluídos no tamanho total */
    }
}
@page {
    size: auto; /* Ajusta a página automaticamente */
    margin: 0; /* Remove as margens da página */
}