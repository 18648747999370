.NewModelo{
    input{
        &::placeholder{
            color: #9d9e9f !important;
        }
    }
    .Dropdown {
        border-radius: 5px;
        background: #373940;
        border: 1px solid #373940 !important;
        box-shadow: none !important;
        width: 100%;
        height: 48px;
        line-height: 30px;
        padding-left: 10px;
        .p-icon.p-dropdown-trigger-icon.p-clickable {
            color: #9d9e9f !important;
        }
        .p-dropdown-trigger {
            position: relative;
        }
        .p-dropdown-panel {
            z-index: 1050;
        }
    }
    .Label {
        span{
            margin-bottom: 6px;
            display: inline-block;
        }
    }
    .p-dropdown {
        span {
            opacity: 1 !important;
            font-weight: 400 !important;
            color: #9d9e9f !important;
        }

    }
}