// Novos Veiculos


.Veiculos {
    .NavVeiculos{
        ul{
            width: max-content;
            list-style: none;
            margin: 0 auto;
            padding: 0;
            text-align: center;
            position: relative;
            z-index: 1;
            &::before{
                content: "";
                position: absolute;
                top: 26px;
                left: 60px;
                width: calc(100% - 120px);
                border-bottom:  2px dotted #373940;
                z-index: -1;
            }
            li{
                list-style: none;
                display: inline-block;
                margin: 0;
                padding: 0;
                color: #FFF;
                min-width: 120px;;
                a{
                    
                    i{
                        display: inline-block;
                        width: 52px;
                        height: 52px;
                        background: #232428;
                        border: 1px solid #555A67;
                        border-radius: 100px;
                        margin-bottom: 10px;
                        line-height: 48px;
                        font-size: 20px;
                        color: #555A67;
                    }
                    span {
                        width: 100%;
                        display: inline-block;
                        color: #D9D9D9;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                    &:hover, &.active{
                        cursor: pointer;
                        i{
                            background:  linear-gradient(136deg, #743BE4 24.43%, #743BE4 171.17%);
                            border: 1px solid #743BE4;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
    .Novo{
        padding: 40px 30px 40px;
        border-radius: 8px;
        background: linear-gradient(180deg, #222328 0%, #202124 100%);
        margin-top: 40px;
        margin-bottom: 40px;
        .HeaderNovo{
            position: relative;
            padding-bottom: 10px;
            text-align: left;
            border-bottom: 1px solid #373940;
            margin-bottom: 30px;
            margin-top: 60px;
            &::before{
                content: "";
                background: #743BE4;
                height: 6px;
                width: 130px;
                position: absolute;
                bottom: -3px;
                left: 0;
                margin-left: 0;
            }
            i{
               color: #763EE7;
               font-size: 32px;
               margin-right: 10px;
               line-height: 0;
            }
            h2{
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal; 
                line-height: 22px;
                text-transform: uppercase;
                margin-top: 10px;
                span{
                    color: #727685;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        
        }
        .FormNovo{

            .Salvar{
                position: relative;
            }
            label{
                width: 100%;
                margin-bottom: 12px;
                > span{
                    color: #727685;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                    display: inline-block;
                    width: 100%;
                }
            }
            .form-check-input:checked, .form-check-input:checked:focus  {
                background-color: var(--primaryColor);
                border-color: var(--primaryColor);
            }
            .form-check-label{
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
            }
            input:not([type=checkbox]){
                border-radius: 6px;
                border: 1px solid #373940 !important;
                box-shadow: none !important;
                background: #373940;
                color: #FFF;
                display: inline-block;
                height: 48px;
            }
            .FormControl{
                width: calc(100% - 59px);
                display: inline-block;
                position: relative;
                i{
                    position: absolute;
                    color: #727685;
                    top: 6px;
                    right: 16px;
                    font-size: 20px;
                }
            }
            textarea{

                &.wrongField {
                    border-left: 7px var(--dangerColor) solid !important;
                    border-radius: 5px;
                }

                border-radius: 6px;
                border: 1px solid #373940 !important;
                box-shadow: none !important;
                background: #373940;
                color: #FFF;
                display: inline-block;
                margin-bottom: 20px;
                height: 110px;
                width: 100%;
                padding: 12px 16px;
                resize: none;
                &::placeholder {
                    font-weight: 600;
                    font-size: 14px;
                    color: #9B9B9B !important;
                }
            }
            .Dropdown{
                border-radius: 5px;
                background: #373940;
                border: 1px solid #373940 !important;
                box-shadow: none !important;
                width: 100%;
                height: 48px;
                line-height: 30px;
                padding-left: 10px;
                .p-icon.p-dropdown-trigger-icon.p-clickable{
                    color: #B1B7D1;
                }
                .p-dropdown-trigger{
                    position: relative;
                }

            }
            .p-dropdown {
                span{
                    color: #FFF !important;
                    opacity: 0.6 !important;
                    font-weight: 400 !important;
                }

            }
            .Btns{
                margin: 40px 10px 0 !important;
                display: inline-block;
                &.Add{
                    margin-top: 0px !important;
                }
                &.ButtonLight {
                    margin: 20px 0 0 !important;
                }
            }
            .TagsInput {
                span{
                    border-radius: 41.85px;
                    border: 0.93px solid #373940;
                    background: #373940;
                    padding: 5px 10px 5px 18px;
                    color: #727685;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-right: 10px;
                    display: inline-block;
                    margin-bottom: 6px;
                    &:hover{
                        background: linear-gradient(90deg, #894FFC 50%, #7139E1 106.74%);
                        color: #FFF;
                    }
                    i{
                        position: relative;
                        top: -2px;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
            .diferenciais{
                input{
                    margin-bottom: 0;
                }
                textarea{

                    &.wrongField {
                        border-left: 7px var(--dangerColor) solid !important;
                        border-radius: 5px;
                    }

                    height: 80px;
                }
            }
            .nao-cadastrar{
                a{
                    color: #555A67;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration-line: underline;   
                }
            }
            .ImagesUploader{
                position: relative;
                display: inline-block;
                margin-right: 20px;
                width: 200px;
                float: left;
                z-index: 1;
                .iu-label{
                    display: none;
                }
                position: relative;
                .iu-loadContainer{
                    color: inherit;
                    width: 200px;
                    height: 140px;
                    display: inline-block;
                    border-radius: 6px;
                    border: 2px dashed #373940;
                    background: none;
                    .iu-dropzone{
                        border: none !important;
                        border-radius: 6px !important;
                        &:hover{
                            background: rgba(39,41,47, 0.2);
                            border-radius: 6px !important;
                        }
                    }
                    span{
                        border-radius: 6px !important;
                        z-index: 2;
                        span{
                            display: none;
                        }
                    }
                }
                > span{
                    width: 100%;
                    height: 136px;
                    position: absolute;
                    top: 2px;
                    left: 0;
                    z-index: -1;
                    text-align: center;
                    background: #191A1E;
                    i{
                        color: #555A67;
                        font-size: 30px;
                        width: 100%;
                        display: inline-block;
                        margin-top: 16px;
                    }
                    p{
                        color: #555A67;
                        font-size: 9px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                    h6{
                        color: #FFF;
                        font-size: 13.02px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
                .form-check-label{
                    font-size: 11px;
                    text-transform: initial;
                    letter-spacing: -0.1px;
                    font-weight: 400;
                }
                .iu-filesInputContainer{
                    margin-top: 0;
                }
                .Label{
                    text-align: center
                }
            }
            .listagem{
                border-radius: 6px;
                border: 1px solid #373940 !important;
                box-shadow: none !important;
                background: #373940;
                color: #FFF;
                display: inline-block;
                margin-bottom: 20px;
                height: 48px;
                width: 100%;
                color: #FFF !important;
                
                .tag {
                    float: left;
                    border-radius: 41.85px;
                    border: 0.93px solid #373940;
                    background: #373940;
                    padding: 5px 10px 5px 18px;
                    color: #FFF !important;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-right: 10px;
                    display: inline-block;
                    margin-bottom: 6px;
                    background: linear-gradient(90deg, #894FFC 50%, #7139E1 106.74%);
                    margin: 6px;
                    i{
                        position: relative;
                        top: -2px;
                        font-style: normal;
                        font-size: 12px;
                        margin-left: 10px;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    &:hover{
                        color: #FFF;
                    }
                    
                }
                .form-control{
                    float: left;
                    width: auto;
                    max-width: 200px;
                    color: #FFF !important;
                    height: 46px !important;
                    margin-bottom: 0;
                    &::placeholder{
                        color: #9B9B9B !important;
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
                
            }
        }
        .statusVeiculo {
            display: none;
            top: -40px;
            right: 0px;
            position: relative;
            float: right;
            .Label.form-check.form-switch{
                display: flex;
                .form-check-input{
                    width: 40px;
                    height: 24px;
                    background-color: #373940;
                    border: 3px solid #373940;
                    order: 2;
                }
                label.form-check-label {
                    order: 1;
                    color: var(--whiteColor);
                    margin-right: 48px;
                    margin-top: 10px;
                    color: #727685;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                }
                .form-check-input:checked, .form-check-input:checked:focus  {
                    background-color: var(--primaryColor);
                    border-color: var(--primaryColor);
                }
            }

        }
    }
    .quill {
        border-radius: 6px;
        border: 1px solid #373940 !important;
        box-shadow: none !important;
        background: #373940;
        .ql-toolbar.ql-snow{
            background: #595959;
            border-color: #595959;
        }
        .ql-snow, .ql-stroke {
            fill: none;
            stroke: #FFF !important;
            &:hover{
                stroke: #FFF !important;
            }
        }
        .ql-container.ql-snow {
            height: 160px;
            border: 1px solid transparent;
            
            &::placeholder{
                color: #FFF !important;
            }
            .ql-editor{
                &::before {
                    color: #FFF !important;
                    font-size: 16px !important;
                    font-family: var(--FontDefault) !important;
                    font-style: normal;
                }
                p{
                    color: #FFF !important;
                    font-size: 16px !important;
                    font-family: var(--FontDefault) !important;
                }
                ul{
                    margin: 0;
                    padding: 0;
                    li{
                        color: #FFF !important;
                        font-size: 16px;
                        margin-bottom: 4px;
                    }
                }
                
            }
        }
    }
    .HeaderLeads{
        border-bottom: none;
    }
    .FormNovo {
        .fotoVideo, .fotoSom{
            .dropzone{
                padding: 0 20px;
                min-height: 100px;
                i {
                    height: 30px;
                    svg{
                        margin-top: -20px;
                    }
                }
            }
            p{
                display: none;
            }
        }
    }
    .statusMultiplos{
        border-radius: 37px;
        border: 1px solid #373940;
        background: #373940;
        height: 30px;
        width: 180px;
        top: -40px;
        right: 0px;
        position: relative;
        float: right;
        span{
            border-radius: 37px;
            border: 1px solid #373940;
            background: #682BDE;
            color: #FFF;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            width: 60px;
            display: inline-block;
            height: 25px;
            line-height: 25px;
            text-align: center;
            position: absolute;
            top: 1px;
            left: 4px;
            transition: all ease-in 0.2s;
            &.on{
                width: 28%;
            }
            &.off{
                width: 28%;
                left: 56px;
            }
            &.vendido{
                width: 40%;
                left: 104px;
            }
        }
        ul{
            margin: 0;
            padding: 0;
            display: inline-block;
            width: 100%;
            z-index: 1;
            position: relative;
            li{
                margin: 0;
                padding: 0;
                display: inline-block;
                color: #5B5F6C;
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                text-align: center;
                &:nth-child(1), &:nth-child(2){
                    width: 30%;
                }
                &:nth-child(3){
                    width: 40%;
                }
                &.ativo{
                    color: #FFF;
                }
                &:hover{
                    color: #FFF;
                    cursor: pointer;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .Veiculos{
        .statusMultiplos{
            top: -110px;
            right: 0px;
        }
        .Novo{
            padding: 40px 20px 40px;
            .HeaderNovo{
                margin-top: 0;
                &.Informacoes{
                    margin-top: 60px;
                }

            }
            .preview-multiple {
                ul {
                    li {
                        .picture {
                            min-width: 98px;
                        }
                    }
                }
            }
            .FormNovo {
                .dropzone.multiple-with-files {
                    span {
                        text-align: center;
                    }
                }
                .ImagesUploader {
                    width: 100%;
                }
            }
        }
        .NavVeiculos{
            margin-top: 60px;
            margin-bottom: -20px;
            ul{
                li{
                    min-width: 56px;
                    span{
                        display: none !important;
                    }
                    a.active{
                        i{
                            width: 52px !important;
                            height: 52px !important;
                            font-size: 20px !important;
                            line-height: 44px !important;
                        }
                    }
                    i{
                        width: 38px !important;
                        height: 38px !important;
                        line-height: 34px !important;
                        font-size: 18px !important;
                    }
                }
                &::before {
                    width: calc(100% - 99px);
                }
            }
        }
    }
}