// PasswordRecovery.scss

$primaryColor: #42C4F2;
$secondaryColor: #1D2039;

.page-login{
  .container-fluid{
      .title {
        display: block !important;
        text-align: center;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;

        h6 {
          color: #8F92A5;
        }

        h4 {
          color: #000000;
        }
      }

      .icon {
        position: absolute;
        color: $primaryColor;
        margin: 5px 10px;
      }

      input {
        padding: 5px;
        background: #FFFFFF;
        border: 1px solid #E1E5FF;
        border-radius: 5px;
        width: 250px;
        padding-left: 40px;
      }

      input::placeholder {
        color: #42496D;
      }

      input:focus {
        outline: none;
      }

      button {
        width: 250px;
        padding: 10px 20px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
      }

      #recovery {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
        margin-top: 40px;
        display: block;
      }

      #recovery:hover {
        background: $secondaryColor;
        transition: background-color 0.2s ease-in-out;
      }

      #back {
        color: #6D6D6D;
        margin-top: 20px;
        box-shadow: none !important;
      }
    }
}