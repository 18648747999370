// ArticleList.scss

.LeadsList {
    margin-bottom: 50px;

    .card {
        padding: 20px 30px 0;
        border-radius: 8px;
        background: linear-gradient(180deg, #222328 0%, #202124 100%);

        .p-datatable .p-datatable-thead {
            >tr {
                >th {
                    font-family: var(--FontSecondary);
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: #696969;
                    text-transform: uppercase;
                    background: none;
                    border: none;
                }
               
            }
        }

        .p-datatable-tbody {
            border-radius: 6px;
            width: 100%;
            a{
                float: right;
            }
            .status-column{
                span{
                    color: #D9D9D9;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;     
                    border-radius: 20px;
                    border: 1px solid #373940;
                    background: #202125; 
                    padding: 6px 20px;
                    strong{
                        font-weight: 400;
                    }
                    i{
                        width: 8px;
                        height: 8px;
                        flex-shrink: 0;
                        background: #278F44;
                        display: inline-block;
                        border-radius: 20px;
                        margin-right: 4px;
                        position: relative;
                        top: 1px;
                    }
                    &.waiting{
                        i{
                            background: var(--warningColor);
                        }
                        
                    }
                    &.new{
                        i{
                            background: var(--primaryColor);
                        }
                        
                    }
                }
            }
            .status-categoria{
                span{
                    color: #D9D9D9;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;     
                    border-radius: 20px;
                    border: 1px solid #373940;
                    background: #202125; 
                    padding: 6px 20px;
                    strong{
                        font-weight: 400;
                    }
                    i{
                        width: 8px;
                        height: 8px;
                        display: inline-block;
                        border-radius: 20px;
                        margin-right: 6px;
                        position: relative;
                        top: -2px;
                        color: #D9D9D9;
                    }
                }
            }
            .actions-column{
                text-align: right;
            }
            .title-column {
                color: #D9D9D9;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            >tr {
                border: 1px solid #2C2D30 !important;
                background: #26272C !important;
                border-left: 10px solid transparent;
                border-radius: 10px 0 0 10px;
                position: relative;
                transition: all ease-in 0.2s;
                >td {
                    border: none;
                    &:last-child {
                        min-width: 170px;
                        text-align: right;
                    }

                    span.status {
                        font-weight: 600;
                        font-size: 13px;
                        margin-left: 4px;
                        top: -1px;
                        position: relative;
                    }

                }

                &:hover {
                    border: 1px solid #2C2D30;
                    background: #373940 !important;
                    td:first-child {
                        &::after {
                            opacity: 1;
                        }
                    }
                }

            }
        }

        .paginator {
            margin-top: 0;
            justify-content: flex-end;
            border: none;
            background: none;

            .p-dropdown-label {
                padding-right: 0;
                line-height: 19px;
            }
        }

        >.pagination {
            min-width: 340px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 166%;
            /* identical to box height, or 20px */

            letter-spacing: 0.4px;

            /* text/secondary */

            color: rgba(0, 0, 0, 0.6);

            // display: grid;
            // grid-template-columns: 4fr 2fr 1fr 1fr;
            // float: right;
            margin: 55px 44.3px 0 0;
            display: flex;
            justify-content: right;

            >.post-page {
                margin-right: 57px;
            }

            >.indice {
                margin-right: 44px;
            }

            >.before {
                margin-right: 40px;
            }

            .before,
            .next {
                cursor: pointer;
                display: flex;
                align-items: center;
                height: 19.91px;
            }
        }

        .p-dropdown {
            margin-left: 0.5rem;
            height: 37px;
            line-height: 9px;

            .p-dropdown-trigger {
                width: 2rem;
            }
        }

        .red-text {
            font-weight: bold;
            color: var(--dangerColor);
        }

        .green-text {
            font-weight: bold;
            color: var(--publicColor);
        }


        .title-column {
            padding-left: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .status-corrigir {
            .bollot{
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #FF8A00;
            }
        }

        .status-aprovada {
            .bollot{
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #A4C52E;
            }
        }

        .status-pendente {
            .bollot{
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: var(--lightColorHover);
            }
        }

        .status-reprovada {
            .bollot{
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: var(--dangerColor);
            }
        }

        .status-aguardando {
            .bollot{
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: var(--primaryColor);
            }
        }

        .fix-button {
            min-width: 118px;
            background-color: var(--publicColor);
            color: var(--whiteColor);
            padding: 10px 20px;
            border-radius: 5px;
            border: none;
            font-family: var(--FontSecondary);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }

        .fix-button:hover {
            background-color: var(--publicColorHover);
        }

        .preview-button {
            min-width: 118px;
            background-color: var(--primaryColorLight);
            color: #0092C9;
            padding: 10px 20px;
            border-radius: 5px;
            border: none;
            font-family: var(--FontSecondary);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }

        .preview-button:hover {
            background-color: var(--primaryColorLightHover);
        }

        .waiting-button {
            min-width: 118px;
            background-color: var(--publicColor);
            color: var(--whiteColor);
            padding: 10px 20px;
            border-radius: 5px;
            border: none;
            font-family: var(--FontSecondary);
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }

        .waiting-button:hover {
            background-color: var(--publicColorHover);
        }

    }
    .Btns{
        &.ButtonIcon{
            margin-left: 10px;
            float: right;
        }

    }
}

@media(min-width: 1024px) and (max-width: 1620px) {
    .LeadsList {
        .card {
            .p-datatable-tbody {
                > tr {
                    > td{
                        padding: 1rem 10px;
                        &.status-column{
                            width: 120px !important;
                            display: inline-block;
                            span {
                                padding: 6px 8px;
                            }
                        }
                        &.status-categoria{
                            width: 164px !important;
                            display: inline-block;
                            span {
                                padding: 6px 8px;
                            } 
                        }
                    }
                }
            }
        }
    }
    
}

@media (max-width: 768px) {
    .filterLeads {
        padding: 30px 0 10px;
    }
    .LeadsList {
        .card{
            padding: 20px 17px 0;
        }
        thead{
            display: none;
        }
        .p-datatable-tbody {

            display: inline-block !important;
            .status-column{
                width: 80px   !important;
                display: inline-block !important;
                float: left;
                padding: 7px 10px;
                strong{
                    display: none;
                }
                span {
                    padding: 5px 5px 6px 9px !important;
                    text-align: center;
                }
            }
            .status-categoria{
                position: absolute;
                top: 0px;
                left: 32px;
                strong{
                    display: none;
                }
                span{
                    padding: 5px 5px 6px 9px !important;
                    
                }
            }
            .title-column {
                width: auto;
                float: left;
                display: inline-block;
                padding: 1rem 4px !important;
            }
            .status-categoria {
                order: 2;
                width: 100px;
                float: left;
            }
            .actions-column {
                border-top: 1px solid #303033 !important;
                margin: auto;
                width: 100% !important;
                display: inline-block;
                padding: 10px !important;
                .View{
                    strong{
                        display: none;
                    }
                }
            }
            .name-column {
                width: 100% !important;
                display: inline-block;
                float: left;
                padding: 6px 14px !important;
                &::before{
                    content: "Nome: ";
                    color: #7d7d7d;
                }
            }
            .mail-column{
                width: 100% !important;
                display: inline-block !important;
                padding: 6px 14px 14px !important;
                &::before{
                    content: "E-mail: ";
                    color: #7d7d7d;
                }
            }
            .hour-column, .data-column{
                padding: 12px 3px !important;
                width: auto !important;
            }
            > tr{
                display: inline-block;
                margin-bottom: 10px;
                > td{
                    padding: 7px 9px !important;
                }
            }
        }
        .status-aguardando, .status-aprovada,  .status-pendente,  .status-reprovada, .status-corrigir  {
            position: absolute;
            top: 0;
            left: 0;
            padding: 5px 10px 2px;
            border-radius: 4px;
            font-size: 10px;
            margin-left: 4px;
            .status{
                font-size: 11px !important;
            }
        }
        .status-aguardando{
            background: rgba(66,196, 242, 0.3);
            color: #27AFE2;
        }
        .status-aprovada{
            background: rgba(108,201, 86, 0.3);
            color: #88A71B;
        }
        .status-pendente{
            background: rgba(39,220, 224, 0.3);
            color: var(--lightColorHover);
        }
        .status-reprovada{
            background: rgba(218,66, 66, 0.3);
            color: var(--dangerColor);
        }
        .status-corrigir{
            background: rgba(255,138, 0, 0.3);
            color: #FF8A00;
        }
    }
    

}