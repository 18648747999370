.HeaderDashboard{
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #343434;
    i{
       color: #763EE7;
       font-size: 32px;
       float: left;
       margin-right: 10px;
       line-height: 0;
    }
    h2{
        color: #FFF;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; 
        line-height: 22px;
        span{
            color: #727685;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

}
.GraphicDashboard{
    .HeaderGraphic{
        padding: 40px 0 20px 0;
        h3{
            color: #727685;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        ul{
            float: right;
            list-style: none;
            display: inline-block;
            li{
                list-style: none;
                display: inline-block;
                a{
                    border-radius: 41.85px;
                    border: 0.93px solid #373940;
                    background: #232428;  
                    color: #727685;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding: 6px 16px;
                    margin-left: 8px;
                    &:hover, &.active{
                        background: linear-gradient(102deg, #894FFB 40.96%, #6E38DB 103.19%);
                        color: var(--whiteColor);
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .Graphic{
        .BarGraphic{
            border-radius: 8px;
            background: linear-gradient(180deg, #222328 0%, #202124 100%);
            padding: 22px 26px;
            height: 340px;
            margin-bottom: 20px;
            p {
                text-align: center;
                color: #CDCDCD;
                padding-top: 40px; 
            }
        }
        .CardGraphic{
            border-radius: 8px;
            background: linear-gradient(180deg, #222328 0%, #202124 100%);
            padding: 22px 26px;
            height: 160px;
            margin-bottom: 20px;
            transition: all ease-in 0.2s;
            p {
                color: #CDCDCD;
            }
            h4{
                color: #7840E7;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                border-bottom: 1px solid #2F3135;
                padding-bottom: 10px;
                font-family: var(--FontTertiary);
            }
            h2{
                color: #FFF;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                float: left;
                margin-top: 10px;
                font-family: var(--FontTertiary);
            }
            i{
                width: 48px;
                height: 48px;
                flex-shrink: 0;
                border-radius: 4px;
                background: #4F535F;
                display: inline-block;
                float: right;
                margin-top: 10px;
                color: #B1B7D1;
                text-align: center;
                font-size: 28px;
            }
            span{
                border-radius: 27px;
                border: 1px solid #4A4B52;
                background: #3E3F44;
                color: #FFF;
                font-size: 10px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding: 4px 10px;
                font-family: var(--FontTertiary);
            }
            hr {
                width: 100%;
                margin: 0;
                height: 0;
                border: none;
            }
            &:hover{
                background: linear-gradient(107deg, #894FFB 17.31%, #6933D5 97.67%);
                h4{
                    color: var(--whiteColor);
                    border-bottom: 1px solid #A078EF;
                }
                i{
                    background: var(--primaryColorEffect);  
                }
                span{
                    background: var(--primaryColorEffect);  
                    border: 1px solid var(--primaryColorEffect);  
                }
            }
        }
    }
}
.LeadsDashboard{
    .HeaderLeads{
        padding: 40px 0 20px 0;
        border: none;
        h3{
            color: #727685;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
    .Leads{
        border-radius: 8px;
        background: linear-gradient(180deg, #222328 0%, #202124 100%);
        padding: 22px 26px;
        margin-bottom: 20px;
        .ItemLeads{
            border-radius: 6px;
            border: 1px solid #2C2D30;
            background: #26272C;
            padding: 6px 12px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all ease-in 0.2s;
            margin: 6px 0;
            &:hover{
                background: #373940;
                cursor: pointer;
            }
            i{
                width: 34px;
                height: 34px;
                display: inline-block;
                background: #303137;
                border-radius: 100px;
                margin-right: 14px;
                text-align: center;
                padding: 0;
                line-height: 32px;
            }
            h3{
                color: #D9D9D9;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                line-height: 36px;
                margin-bottom: 0;
            }
            h5{
                border-radius: 45px;
                border: 1px solid #373940;
                background: #232428;
                display: inline-block;
                padding: 6px 16px;
                color: #B1B7D1;
                font-size: 13px;
                line-height: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            span{
                width: 10px;
                height: 10px;
                background: #303137;
                border-radius: 20px;
                margin: 12px 20px;
                &.active{
                    background: #278F44;
                }
                &.disable{
                    background: #B7544B;
                }
            }
            button{
                width: 30px;
                height: 30px;
                border: 1px solid #55575C;
                background: none;
                border-radius: 100px;
                color: #55575C;
                font-size: 20px;
                text-align: center;
                padding: 0;
                line-height: 18px;
            }
            &:hover {
                button{
                    background:  linear-gradient(136deg, #743BE4 24.43%, rgba(116, 59, 228, 0.00) 171.17%);
                    border: 1px solid transparent;
                    color: #FFFFFF;
                }
            }
            .push {
                margin-left: auto;
                margin-bottom: 0;
                font-family: var(--FontTertiary);
            }
        }
        .SeeAll{
            background: none;
            border-radius: 45px;
            border: 1px solid #373940;
            display: flex;
            width: 216px;
            padding: 9px 49px;
            justify-content: center;
            align-items: center;
            color: #FFF;
            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: auto;
            margin-top: 20px;
            margin-bottom: 0;
            transition: all ease-in 0.2s;
            &:hover{
                background: #373940;
            }
        }
    }
}
.BarGraphic{
    line{
        stroke: rgba(158, 158, 157, 0.2) !important;
    }
    path{
    }
    svg{
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {

}
  
@media(min-width: 1024px) and (max-width: 1620px) {

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .GraphicDashboard {
        .Graphic {
            .BarGraphic {
                height: 185px;
                padding: 18px 12px;
            }
            .CardGraphic {
                padding: 14px 16px;
                position: relative;
                h2 {
                    font-size: 22px;
                    width: 100px;
                }
                hr {
                    display: none;
                }
                i {
                    width: 36px;
                    height: 36px;
                    margin-top: 3px;
                    font-size: 20px;
                    line-height: 33px;
                    position: absolute;
                    top: 50px;
                    right: 16px;
                }
            }
        }
        .HeaderGraphic{
            padding: 40px 0 10px 0;
            ul{
                display: none;
            }
            h3{
                font-size: 16px;
                padding: 0 15px;
            }
        }
    }
    .HeaderDashboard {
        padding: 20px 0 10px 0;
        h2 {
            font-size: 17px;
            span {
                font-size: 13px;
                line-height: 14px;
                display: inline-block;
                width: 80%;
                margin-top: 8px;
            }
        }
        i {
            margin-bottom: 20px;
        }
        .menu{
            float: right;
            color: #8047F0;
            font-size: 40px;
            top: -12px;
            right: -10px;
            position: relative;
        }
    }
    .LeadsDashboard {
        .HeaderLeads {
            padding: 20px 15px 0px 15px;
            h3{
                margin-bottom: 10px;
                font-size: 16px;
            }
        }
        .Leads {
            padding: 6px 12px 20px;
            margin-bottom: 20px;
            .ItemLeads {
                padding: 6px 12px;
                display: inline-block;
                width: 100%;
                h3 {
                    width: calc(100% - 40px);
                    font-size: 14px;
                    line-height: 27px;
                }
                i {
                    float: left;
                    margin-top: 7px;
                }
                button {
                    margin-top: -16px;
                    float: right;
                }
                h5 {
                    padding: 3px 12px;
                    font-size: 9px;
                    position: relative;
                    top: -8px;
                }
            }
        }
    }
}