// FilterArticles.scss

.filterUsuarios{
    display: inline-block;
    padding: 30px 0;
    text-align: right;
    .icon{
        width: 120px;
        display: flex;
        align-items: center;
        span{
            background: var(--primaryColor);
            font-family: var(--FontSecundary);
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #666666;
            margin-left: 10px;
        }
    }
    .filtro{
        box-shadow: none;
        border: 1px solid #DADADA;
        border-radius: 41.85px;
        border: 1px solid #373940;
        background: #232428;
        padding: 7px 7px 7px 20px;
        line-height: 20px;
        &:hover, &:focus{
            border: 1px solid var(--primaryColor);
            outline: none !important;
        }
        span{
            padding: 0;
            color: #B1B7D1;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
        }

        .p-icon.p-dropdown-trigger-icon.p-clickable{
            color: #FFFFFF;
        }
    }
}