@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// standard colors
:root {
  --primaryColor: #8C52FF;
  --primaryColorHover: #611BED;
  --primaryColorEffect: #5429A8;
  --primaryColorLight: #C8ECFC;
  --primaryColorLightHover: #AFE6FF;
  --primaryColorHeavy: #0092C9;

  --secondaryColor: linear-gradient(90deg, #505561 2.37%, #5F6472 104.27%);
  --secondaryColorLight: #2B2E4C;
  --secondaryColorHover: #202864;
  --publicColor: #1DCC43;
  --publicColorHover: #14AD36;
  --lightColor: #E9E9E9;
  --lightColorHover: #DADCE0;
  --whiteColor: #FFFFFF;

  --warningColor: #E3B100;
  --dangerColor: #F04765;
  --soldColor: #757575;
  --dangerColorHover: #C61616;

  --FontDefault: 'Raleway', sans-serif;
  --FontSecondary: 'Poppins', sans-serif;
  --FontTertiary: 'Roboto', sans-serif;
}

body {
  font-family: var(--FontDefault) !important;
  background: #EEEEF2 !important;
}

.App {
  text-align: center;
}
*:focus, *:hover{
  outline: none !important;
  box-shadow: none !important;
}
a {
  text-decoration: none !important;
}

.p-dropdown-item {
  padding: 0.3rem 1rem !important;
}
#my-tooltip {
  max-width: 200px !important;
  word-wrap: break-word;
  text-align: center;
  font-size: 12px !important;
  font-family: var(--FontTertiary);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* Components */
body{
  background: #010101 !important;
  &::after{
    content: "";
    position: fixed;
    background: url(./img/bg-container.png) no-repeat right bottom;
    width: 318px;
    height: 258px;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
  &::before{
    content: "";
    position: fixed;
    background: url(./img/bg-container-top.png) no-repeat top right;
    width: 401px;
    height: 129px;
    top: 0;
    right: 0;
    z-index: 0;
  }
}
.Content {
  width: calc(100% - 290px);
  margin-left: 290px;
  height: 100vh;
  padding: 60px;
  z-index: 1;
  position: relative;
}

/* Form Components */
.Links {
  &.LinkPrimary {
    background: none;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #9B9B9B;
    position: relative;
    width: max-content;

    &:hover,
    &.active,
    &:active,
    &:focus {
      background: none !important;
      color: var(--secondaryColor) !important;

      &::before {
        content: "";
        position: absolute;
        width: 60px;
        left: 50%;
        margin-left: -30px;
        height: 4px;
        margin-top: -6px;
        background: linear-gradient(270deg, #4BDBDB 2.66%, #42C4F2 98.58%);
      }
    }
  }
}

.Btns {
  border: none !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.04em;
  text-decoration: none;
  transition: all ease-in 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.New{
    border-radius: 41.85px;
    border: 1px solid #373940 !important;
    background: #232428;
    color: #B1B7D1;
    font-size: 13.02px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 8px 14px 8px 20px;
    margin-left: 10px;
    top: -1px;
    position: relative;
    i{
      border-left: 1px solid #2F3135;
      margin-left: 10px;
      padding-left: 10px;
      font-size: 15px;
      line-height: 0;
      height: 14px;
    }
    &:hover{
      background: linear-gradient(102deg, #894FFB 40.96%, #6E38DB 103.19%);
      i{
        border-left: 1px solid rgba(255, 255, 255, 0.50);
      }
    }
  }

  &.View{
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 41.85px;
    border: 0.93px solid #373940;
    background: linear-gradient(102deg, #894FFB 40.96%, #6E38DB 103.19%);
    box-shadow: 0px 3px 4px 0px rgba(55, 57, 64, 0.09); 
    float: right;
    padding: 4px 12px 6px 20px;
    strong{
      font-weight: 500;
    }
    i{
      margin-left: 10px;
      font-size: 15px;
      position: relative;
      top: -1px;
    }
  }

  &.ButtonPrimary {
    background: var(--secondaryColor);
    padding: 8px 40px;
    color: var(--whiteColor);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 20px;
    display: inline-block;
    &:hover {
      background: var(--primaryColorHover) !important;
    }
  }

  &.ButtonIcon{
    border: 1px solid #55575C !important;
    background: #232428;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 60px;
    color: #55575C;
    padding: 0 !important;
    line-height: 18px;
    &:hover {
      background: var(--232428) !important;
      color: var(--whiteColor);
    }
  }

  &.ButtonPrimaryLight {
    background: var(--primaryColor);
    padding: 12px 40px;
    color: var(--whiteColor);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 20px;
    display: inline-block;
    &:hover {
      background: var(--primaryColorHover) !important;
    }
  }

  &.ButtonSecondary {
    background: none;
    padding: 12px 40px;
    color: var(--whiteColor);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 20px;
    display: inline-block;
    border: 1px solid #373940 !important;
    &:hover {
      background: #373940 !important;
    }
  }
  &.ButtonSecondaryLight{
    background: none;
    padding: 12px 40px;
    background: #373940;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 20px;
    display: inline-block;
    &:hover {
      background: #373940 !important;
    }
  }

  &.ButtonLight {
    color: #555A67;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    background: none;

    &:hover {
      background: none;
      color: var(--lightColorHover);
    }
    &:focus, &:active{
      background: none !important;
    }
  }

  &.ButtonEdit {
    background: var(--primaryColor);
    text-transform: uppercase;
    color: #FFFFFF;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-size: 14px;
    padding: 12px 40px;

    &:hover {
      background: var(--primaryColorHover) !important;
    }
    
  }

  &.ButtonPublic {
    background: var(--publicColor);
    text-transform: uppercase;
    color: #FFFFFF;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-size: 14px;
    padding: 12px 40px;

    &:hover {
      background: var(--publicColorHover) !important;
    }
  }

  &.ButtonBack {
    background: none;
    display: inline-block;
    color: #7E7E7E;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    &:hover,
    &:active {
      background: none !important;
      color: #7E7E7E !important;
    }
  }

  &.ButtonDanger {
    background: var(--dangerColor);
    padding: 12px 40px;

    &:hover,
    &:active {
      background: var(--dangerColorHover) !important;
    }
  }

  &.Add{
    width: 39px;
    height: 39px;
    border-radius: 100px;
    border: 1px solid #55575C !important;
    background: none;
    color: #55575C;
    line-height: 23px;
    text-align: center;
    padding: 0;
    font-size: 26px;
    &:hover {
      background: var(--primaryColorHover) !important;
      border: 1px solid var(--primaryColorHover) !important;
    }
  }
  &.Remove {
    width: 39px;
    height: 39px;
    border-radius: 100px;
    border: 1px solid #55575C !important;
    background: none;
    color: #55575C;
    line-height: 23px;
    text-align: center;
    padding: 0;
    font-size: 26px;
    &:hover {
      background: var(--primaryColorHover) !important;
      border: 1px solid var(--primaryColorHover) !important;
    }
  }
}

.Inputs {
  background: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 300 !important;
  font-family: var(--FontTertiary);
  border: 1px solid #DADADA !important;
  box-shadow: 0px 2px 6px rgba(240, 240, 240, 0.25) !important;
  border-radius: 8px;
  padding: 14px 16px !important;

  &::-webkit-input-placeholder {
    font-weight: 600;
    font-size: 14px;
    color: #9B9B9B !important;
  }

  .Icon {
    padding-left: 30px;
  }

  &:-ms-input-placeholder {
    font-weight: 600;
    font-size: 14px;
    color: #9B9B9B !important;
  }

  &::placeholder {
    font-weight: 600;
    font-size: 14px;
    color: #9B9B9B !important;
  }
}
.Label {
  textarea{
    letter-spacing: 1px;
    font-weight: 300 !important;
    font-family: var(--FontTertiary);
  }
}


.Title {
  &.titlePrimary {
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 38px;
    color: #292D4D;
    position: relative;
    margin-bottom: 20px;

    &::before {
      content: "";
      position: absolute;
      width: 60px;
      left: 0;
      margin-left: 0;
      height: 4px;
      margin-top: -6px;
      background: linear-gradient(270deg, #4BDBDB 2.66%, #42C4F2 98.58%);
    }
  }

  &.titleSecondary {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 304.4%;
    text-transform: uppercase;
    color: #9696AA;
    border-bottom: 2px solid #CDCDCD;
    position: relative;
    margin-bottom: 14px;

    &::before {
      content: "";
      position: absolute;
      width: 60px;
      left: 0;
      height: 2px;
      bottom: -2px;
      background: linear-gradient(270deg, #4BDBDB 2.66%, #42C4F2 98.58%);
    }
  }
}

.HeaderTitle {
  background: #FFFFFF;
  border: 1px solid #DADADA;
  box-shadow: 0px 2px 6px rgba(240, 240, 240, 0.25);
  border-radius: 8px;
  margin-top: -1px;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: 16px 30px 16px 30px;
  z-index: 1;
  position: relative;

  >span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    display: inline-block;

    &::before {
      content: "";
      background: linear-gradient(270deg, #4BDBDB 2.66%, #42C4F2 98.58%);
      height: 5px;
      width: 60px;
      display: flex;
      position: absolute;
      left: 0;
      bottom: -16px;
    }
  }
}

.Tab {
  &.TabPrimary {
    background: #F5F5F5;
    border: 1px solid #DADADA;
    box-shadow: 0px 2px 6px rgba(240, 240, 240, 0.25);
    border-radius: 8px 8px 0px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #1F1F1F;
    padding: 10px 24px;
    position: relative;
    margin-right: 2px;
    margin-bottom: -1px;

    &:hover,
    &.active {
      background: #FFFFFF;
      color: #1F1F1F;
      border: 1px solid #DADADA;

      &::before {
        content: "";
        position: absolute;
        width: calc(100% - 48px);
        left: 24px;
        height: 4px;
        bottom: -1px;
        background: linear-gradient(270deg, #4BDBDB 2.66%, #42C4F2 98.58%);
      }
    }
  }
}

.Modal {
  .HeaderTitle {
    padding: 16px 30px 16px 30px;
    margin-top: 0;
    margin-left: 0;
    width: 100%;

    .modal-title {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      position: relative;
      display: inline-block;

      &::before {
        content: "";
        background: linear-gradient(270deg, #4BDBDB 2.66%, #42C4F2 98.58%);
        height: 5px;
        width: 60px;
        display: flex;
        position: absolute;
        left: 0;
        bottom: -16px;
      }
    }
  }

  .modal-body {
    text-align: center;
    width: 100%;
    padding: 40px 0;

    a {
      text-align: center;
      padding-bottom: 10px;
      display: block;

      .Btns {
        padding: 10px 30px;
        width: 60%;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
      }
    }
    
  }

}

.Alerts {
  background: #ADAFB5;
  box-shadow: 0px 4px 10px rgba(171, 168, 168, 0.25);
  border-radius: 4px;
  padding: 10px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 390px;
  height: auto;
  transition: all ease-in 0.2s;
  text-align: left;
  z-index: 10000;

  &:before {
    content: "";
    position: absolute;
    width: 4px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 4px;
    left: 7px;
  }

  i.icon {
    width: 38px;
    height: 38px;
    background: #FFFFFF;
    display: inline-block;
    border-radius: 100px;
    margin: 6px 0 0 12px;
    float: left;
    text-align: center;
    font-size: 22px;
    color: #ADAFB5;
    position: absolute;
  }

  i.close {
    right: 10px;
    top: 6px;
    position: absolute;
    color: #FFFFFF;
    transition: all ease-in-out 0.3s;

    &:hover {
      transform: rotate(90deg);
      cursor: pointer;
    }
  }

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    display: inline-block;
    width: 280px;
    margin-left: 60px;
    margin-top: 5px;
    margin-bottom: 4px;

    span {
      font-weight: 400;
      width: 100%;
      display: block;
      font-size: 11px;
      margin-bottom: 0;
      margin-top: -2px;
    }
  }

  &.success {
    background: var(--publicColor);

    i.icon {
      color: var(--publicColor);
    }
  }

  &.warning {
    background: var(--warningColor);

    i.icon {
      color: var(--warningColor);
    }
  }

  &.danger {
    background: var(--dangerColor);

    i.icon {
      color: var(--dangerColor);
    }
  }

  &.false {
    bottom: -100px;
  }

  &.true {
    bottom: 20px;
  }
}

.react-tooltip {
  padding: 4px 8px !important;
  color: #202124;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modal-content{
  background: #26272C  url(./img/bg-container.png) no-repeat bottom right !important;
  .modal-header {
   border-radius: 5px;
   background: linear-gradient(90deg, #8A50FD 0%, #7038E0 100%);
   padding: 22px 30px !important;
   border-bottom: none;
   position: relative;
   margin-bottom: 30px;
       .modal-title{
           color: #FFF;
           font-size: 16px;
           font-style: normal;
           font-weight: 700;
           line-height: normal;
           text-transform: uppercase;
       }
       svg{
           font-size: 40px;
           color: #905DF3;
           position: absolute;
           right: 20px;
       }
   }
   .modal-body{
       padding: 10px 30px 30px 30px;
       input:not([type=checkbox]){
           border-radius: 6px;
           border: 1px solid #43454D;
           background: #373940;
           color: #FFF;
           text-align: left;
           font-size: 14px;
           line-height: 28px !important;
           font-style: normal;
           font-weight: 400;
           line-height: normal;
           margin-bottom: 0px;
           &::placeholder{
               color: #FFF;
           }
       }
       .Label{
           width: 100%;
       }
       label{
           margin-top: 0px;
           color: #FFF;
           font-size: 12px;
           font-style: normal;
           font-weight: 500;
           line-height: normal;
           text-transform: uppercase;
       }
       .form-check-label{
        text-transform: capitalize
       }
       .ButtonLight{
           color: #FFF;
           margin-top: 10px;
           font-weight: 500;
       }
       .Dropdown{
           border-radius: 5px;
           background: #373940;
           border: 1px solid #373940 !important;
           box-shadow: none !important;
           width: 100%;
           height: 48px;
           line-height: 30px;
           padding-left: 10px;
           .p-icon.p-dropdown-trigger-icon.p-clickable{
               color: #B1B7D1;
           }
           .p-dropdown-trigger{
               position: relative;
           }

       }
       .p-dropdown {
           span{
               color: #FFF !important;
               font-weight: 400 !important;
               text-transform: capitalize !important;
               font-size: 14px;
           }

       }
       .dropdown-container {
           border-radius: 5px;
           background: #373940;
           border: 1px solid #373940 !important;
           color: #FFF;
           .dropdown-content{
               * {
                   padding: 0;
                   border: none;
                   margin: 0;
                   color: #373940;
               }
               div{
                   padding: 5px;
               }
               input[type=text]{
                   background: #cdcdcd !important;
                   padding: 5px 10px;
                   color: #373940;
                   &::placeholder{
                       color: #373940;
                   }
               }
               label{
                   padding: 5px 10px;
                   border-bottom: 1px solid #cdcdcd;
                   font-size: 15px;
               }
               input[type=checkbox]{
                   margin-right: 6px;

               }
               button{
                   right: 14px;
               }
               
           }
       }
       textarea{
        border-radius: 6px;
        border: 1px solid #43454D;
        background: #373940;
        color: #FFF;
        text-align: left;
        font-size: 14px;
        line-height: 28px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0px;
        width: 100%;
        min-height: 100px;
        padding: 5px 10px;
        resize: none;
       }
       h4{
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        border-bottom: 1px solid #373940;
        padding-bottom: 10px;
        position: relative;
        margin-bottom: 20px;
        margin-top: 10px;
        &::before{
          content: "";
          width: 60px;
          height: 5px;
          background: var(--primaryColor);
          position: absolute;
          bottom: -3px;
          left: 0;
        }
       }
   }
}

@media(min-width: 1621px) and (max-width: 1800px) {
  .Content {
    padding: 4%;
  }
}

@media(min-width: 1024px) and (max-width: 1620px) {
  .Content {
    width: calc(100% - 260px);
    margin-left: 260px;
    padding: 60px 30px;
  }

  .volume-chart {
    .box {
      .HeaderTitle {
        >span {
          font-size: 14px;
        }

        .filter {
          span {
            font-size: 12px !important;
          }

          ul {
            li {
              width: 80px !important;
            }
          }
        }
      }
    }
  }

  .Title.titlePrimary {
    font-size: 28px;
  }
  .Btns{
    &.View {
      padding: 4px 8px 6px 14px;
      i {
        margin-left: 4px;
      }
    }
    &.ButtonPrimary {
      padding: 8px 15px;
      font-size: 12px;
    }
  }
}

@media(min-width: 1024px) and (max-width: 1280px) {
  .Content {
    width: calc(100% - 200px);
    margin-left: 200px;
    padding: 60px 20px;
  }

  .HeaderTitle {
    padding: 12px 10px 12px 20px;

    >span {
      font-size: 14px;
    }
  }

  .volume-chart {
    height: 260px;
    .box {
      .HeaderTitle {
        >span {
          font-size: 13px;
          &::before {
            content: "";
            bottom: -12px;
          }
        }
       
      }
    }
  }
}
@media (max-width: 768px) {
  .Content {
    width: 100%;
    margin-left: 0;
    padding: 20px 10px 20px 10px !important;
    margin-bottom: 20px;
    height: auto;
    &.Dashboard{
      padding: 40px 20px 20px 20px;
      border-radius: 10px 10px 0 0;
      margin-bottom: 20px;
      position: relative;
      z-index: 2;
    }
    &.ResultPost{
      margin-bottom: 0px;
    }
  }
  .HeaderTitle {
    padding: 10px 19px 10px 18px;
    background: none;
    > span {
      font-size: 12px;
      &::before {
        bottom: -10px;
      }
    }
  }
  .Links.LinkPrimary {
    font-size: 15px;
    padding: 0;
  }
  .Btns{
    &.ButtonSecondary, &.ButtonPrimary {
      padding: 12px 14px;
      font-size: 12px;
      margin: 0 5px;
    }
  }
  .Title.titlePrimary {
    border-bottom: 1px solid #CBCBCE;
    font-size: 18px;
    &::before {
      bottom: -2px;
    }
  }
  .Alerts {
    width: 320px;
  }
  .p-input-icon-left, .p-input-icon-right {
    width: 100%;
    input {
        width: 100% !important;
    }
  }
  body{
    &::after {
      display: none;
    }
  }
}