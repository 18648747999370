.modal-content {
    .react-datepicker-wrapper {
        width: 100%;
    }

    .form-switch {
        margin-top: 10px;
        margin-bottom: 10px;

        .form-check-input:checked,
        .form-check-input:checked:focus {
            background-color: var(--primaryColor);
            border-color: var(--primaryColor);
        }

        .form-check-label {
            color: #727685;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            display: inline-block;
        }
    }

}

.ModalDestaqueNew {
    .DatePicker {

        .react-datepicker__input-container{
              display: flex;
            align-items: center;
            justify-content: center;
        }

    
        svg {
            //padding: 14px;
            left: 0;
            fill: #fff;
        }
        // input{
        //     padding-left: 30px;
        // }

    }
    .checkbox{
        .Label{
            color: #727685;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            display: inline-block;
            line-height: 24px;
            margin-right: 20px;
            .form-check-label{
                margin-left: 10px;
            }  
        }
        .form-check-input {
            border: 3px solid #464850;
            width: 18px;
            height: 18px;
            background-color: #464850;
        }
        .form-check-input:checked {
            background-color: #743BE4;
            background-image: none;
        }
    }




}