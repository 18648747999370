// ShowPassword.scss

.show-password{
    position: relative;
    top: 0;
    right: 55px;

    .preview-password{
        position: absolute;
        cursor: pointer;
    }
}