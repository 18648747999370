// Novos Veiculos
.EsteticaNovo {
    .NovoEstetica{
        padding: 60px 40px 40px;
        border-radius: 8px;
        background: linear-gradient(180deg, #222328 0%, #202124 100%);
        margin-top: 40px;
        margin-bottom: 40px;
        .row{
            > div {
                > h3{
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                    padding-bottom: 16px;
                    border-bottom: 1px solid #373940;
                    position: relative;
                    margin-bottom: 20px;
                    &::before{
                        content: "";
                        width: 48px;
                        height: 6px;
                        background: #743BE4;
                        position: absolute;
                        left: 0;
                        bottom: -3px;
                    }
                }
            }
        }

        label{
            width: 100%;
            margin-bottom: 12px;
            > span{
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
                width: 100%;
            }
        }
        input:not([type=checkbox]):not([type=radio]){
            border-radius: 6px;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            background: #373940;
            color: #FFF;
            display: inline-block;
            margin-bottom: 20px;
            height: 48px;
        }
        .FormControl{
            width: calc(100%);
            display: inline-block;
            position: relative;
            i{
                position: absolute;
                color: #727685;
                top: 10px;
                right: 16px;
                font-size: 20px;
            }
        }
        textarea{
            border-radius: 6px;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            background: #373940;
            color: #FFF;
            display: inline-block;
            margin-bottom: 20px;
            height: 110px;
            width: 100%;
            padding: 12px 16px;
            resize: none;
            &::placeholder {
                font-weight: 600;
                font-size: 14px;
                color: #9B9B9B !important;
            }
        }
        .Dropdown{
            border-radius: 5px;
            background: #373940;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            width: 100%;
            height: 48px;
            line-height: 30px;
            padding-left: 10px;
            .p-icon.p-dropdown-trigger-icon.p-clickable{
                color: #B1B7D1;
            }
            .p-dropdown-trigger{
                position: relative;
            }

        }
        .p-dropdown {
            span{
                color: #FFF !important;
                opacity: 0.6 !important;
                font-weight: 400 !important;
            }

        }
        .Label{
            color: #727685;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            display: inline-block;
            line-height: 24px;
            margin-right: 20px;
            margin-bottom: 2px;
            .form-check-label{
                margin-left: 10px;
            }  
        }
        .form-check-input {
            border: 3px solid #464850;
            width: 18px;
            height: 18px;
            background-color: #464850;
        }
        .form-check-input:checked {
            background-color: #743BE4;
            background-image: none;
        }
        .DatePicker{
            display: flex;
            margin-bottom: 20px;
            .react-datepicker-wrapper{
                display: inline-block;
                float: left;
                margin-right: 14px !important;
                width: calc(50% - 8px);
                &:nth-child(2), &:nth-child(3){
                    margin-right: 0 !important;
                }
                svg{
                    padding: 14px;
                    fill: var(--whiteColor);
                }
                input{
                    padding-left: 40px;
                    width: 100%;
                }
            }
            .react-datepicker__tab-loop{
                order:3;
            }
        }
        .text-right{
            text-align: right;
        }
        .ImagesUploader{
            position: relative;
            display: inline-block;
            margin-right: 20px;
            width: 100%;
            float: left;
            z-index: 1;
            .iu-label{
                display: none;
            }
            position: relative;
            .iu-loadContainer{
                color: inherit;
                width: 100%;
                height: 200px;
                display: inline-block;
                border-radius: 6px;
                border: 2px dashed #373940;
                background: none;
                .iu-dropzone{
                    border: none !important;
                    border-radius: 6px !important;
                    &:hover{
                        background: rgba(39,41,47, 0.2);
                        border-radius: 6px !important;
                    }
                }
                span{
                    border-radius: 6px !important;
                    z-index: 2;
                    span{
                        display: none;
                    }
                }
            }
            .iu-filesInputContainer{
                width: 100%;  
            }
            > span{
                width: 100%;
                height: 134px;
                position: absolute;
                top: 8px;
                left: 0;
                z-index: -1;
                text-align: center;
                background: #191A1E;
                i{
                    color: #555A67;
                    font-size: 30px;
                    width: 100%;
                    display: inline-block;
                    margin-top: 40px;
                }
                p{
                    color: #555A67;
                    font-size: 9px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                h6{
                    color: #FFF;
                    font-size: 13.02px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
            .form-check-label{
                font-size: 11px;
                text-transform: initial;
                letter-spacing: -0.1px;
                font-weight: 400;
            }
            .Label{
                text-align: center
            }
        }
        .listagem{
            border-radius: 6px;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            background: #373940;
            color: #FFF;
            display: inline-block;
            margin-bottom: 20px;
            height: 48px;
            width: 100%;
            color: #FFF !important;
            
            .tag {
                float: left;
                border-radius: 41.85px;
                border: 0.93px solid #373940;
                background: #373940;
                padding: 5px 10px 5px 18px;
                color: #FFF !important;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-right: 10px;
                display: inline-block;
                margin-bottom: 6px;
                background: linear-gradient(90deg, #894FFC 50%, #7139E1 106.74%);
                margin: 6px;
                i{
                    position: relative;
                    top: -2px;
                    font-style: normal;
                    font-size: 12px;
                    margin-left: 10px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                &:hover{
                    color: #FFF;
                }
                
            }
            .form-control{
                float: left;
                width: auto;
                max-width: 200px;
                color: #FFF !important;
                height: 46px !important;
                margin-bottom: 0;
                &::placeholder{
                    color: #9B9B9B !important;
                    font-weight: 600;
                    font-size: 14px;
                }
            }
            
        }
    }
    .HeaderEstetica{
        position: relative;
        padding-bottom: 20px;
        border-bottom: 1px solid #343434;
        i{
           color: #763EE7;
           font-size: 32px;
           float: left;
           margin-right: 10px;
           line-height: 0;
        }
        h2{
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; 
            line-height: 22px;
            span{
                color: #727685;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    
    }
    .accordion{
        .accordion-item{
            border-radius: 6px;
            border: 1px solid #373940;
            background: #191A1E !important;
            margin-bottom: 20px ;
            
            .accordion-button{
                h4{
                    color: #FFF;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
                i{
                    top: -4px; 
                }
            }
            .filterSearce {
                padding-top: 10px;
                padding-bottom: 10px;
                input {
                    border-radius: 43px;
                    border: 1px solid #373940;
                    background: #232428;
                    height: 36px;
                }
            }
            .accordion-header{
                padding: 20px 30px;
            }
            .accordion-body {
                padding: 20px 30px;
                background: #27292F;
                .cadastroItem{
                    font-family: var(--FontTertiary);
                    padding-top: 30px;
                    margin-top: 0px;
                    border-top: 1px solid #393C43;
                    display: inline-block;
                    padding-bottom: 20px;
                    width: 100%;
                    .item{
                        display: inline-block;
                        border-radius: 6px;
                        border: 1px solid #4C4E55;
                        background: #373940;
                        width: 13.5%;
                        height: 140px;
                        margin-right: 10px;
                        text-align: center;
                        float: left;
                        padding: 15px;
                        position: relative;
                        margin-bottom: 16px;
                        i{
                            color: #FFF;
                            position: absolute;
                            top: 8px;
                            right: 12px;
                            display: none;
                        }
                        h3{
                            color: #FFF;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 22px;
                            line-height: normal;
                            padding: 10px 0 0;
                        }
                        h4{
                            color: #FFF;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            padding: 0;
                            margin-bottom: 0;
                        }
                        p{
                            color: #949494;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;  
                            width: 100%;
                            white-space: nowrap;
                            margin-top: 8px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        span{
                            color: #949494;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;  
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding-bottom: 6px;
                        }
                        .Acoes{
                            border-top: 1px solid #4C4E55;
                            padding: 10px 0 0 0;
                            margin-top: 14px;
                        }
                        .infos{
                            height: 47px;
                            i {
                                color: #FFF;
                                position: absolute;
                                top: -14px;
                                left: 17px;
                                height: 30px;
                                width: 30px;
                                background: #743BE4;
                                z-index: 1;
                                border-radius: 20px;
                            }
                        }
                    }
                    .addVariacao{
                        border-radius: 6px;
                        background: #1F2026;
                        border: 1px solid #4C4E55;
                        width: 13.5%;
                        height: 140px;
                        text-align: center;
                        display: flex;
                        float: left;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        transition: all ease-in-out 0.2s;
                        &:hover{
                            background: #373940;
                            cursor: pointer;
                            .Add{
                                background: var(--primaryColorHover) !important;
                                border: 1px solid var(--primaryColorHover) !important;
                                color: #FFF;
                              }
                        }
                    }
                }
                .editar{
                    text-align: right;
                    margin-top: 15px;
                    right: -40px;
                    position: relative;
                }
                .statusAdcionais{
                    text-align: right;
                    padding-top: 14px;
                    .form-check {
                        display: flex;
                        .form-check-label {
                            margin-right: 56px;
                            margin-top: 6px;
                        }
                    }
                    .Label.form-check.form-switch{
                        display: flex;
                        margin-top: 5px;
                        .form-check-input{
                            width: 40px;
                            height: 24px;
                            background-color: #373940;
                            border: 3px solid #373940;
                            order: 2;
                        }
                        label.form-check-label {
                            order: 1;
                            color: var(--whiteColor);
                            margin-right: 48px;
                            margin-top: 10px;
                            color: #727685;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                        }
                        .form-check-input:checked, .form-check-input:checked:focus  {
                            background-color: var(--primaryColor);
                            border-color: var(--primaryColor);
                        }
                    }
                    .Btns{
                        display: inline-block;
                        float: right;
                        margin-left: 20px;
                        border: 1px solid #55575C !important;
                    }
                }
                
            }
            .ButtonSecondaryLight{
                margin-right: 40px;
                padding: 6px 30px;
                top: -1px;
                position: relative;
                &:hover{
                    background: var(--primaryColor) !important;
                }
            }
            .prevStatus{
                border-radius: 20px;
                width: 14px;
                height: 14px;
                display: inline-block;
                
                right: 50px;
                top: 2px !important;
                position: absolute;
                &.danger{
                    background: var(--dangerColor);
                }
                &.sucess{
                    background: var(--publicColor);
                }
            }
            .Btns{
                &.ButtonIcon {
                    border: 1px solid #55575C !important;
                    background: #232428;
                    display: inline-block;
                    width: 38px;
                    height: 38px;
                    line-height: 25px;
                    border-radius: 60px;
                    color: #55575C;
                    font-size: 23px;
                    padding: 0;
                    margin-left: 8px !important;
                    &:hover{
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {

}