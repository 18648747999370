// Novo ImagensCarros
.formImagensCarros{
    border-radius: 8px;
    background: linear-gradient(180deg, #222328 0%, #202124 100%);
    padding: 30px 50px !important;
    margin-top: 30px !important;
    .HeaderForm {
        position: relative;
        padding-bottom: 20px;
        text-align: left;
        border-bottom: 1px solid #373940;
        margin-bottom: 30px !important;

        &::before {
            content: "";
            background: #743BE4;
            height: 6px;
            width: 130px;
            position: absolute;
            bottom: -3px;
            left: 0;
        }

        i {
            color: #763EE7;
            font-size: 32px;
            margin-right: 6px;
            line-height: 0;
        }

        h2 {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            line-height: 22px;
            text-transform: uppercase;
            margin-top: 10px;

            span {
                color: #727685;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

    }
    .statusVeiculo {
        top: -40px;
        right: 0px;
        position: relative;
        float: right;
        .Label.form-check.form-switch{
            display: flex;
            .form-check-input{
                width: 40px;
                height: 24px;
                background-color: #373940;
                border: 3px solid #373940;
                order: 2;
            }
            label.form-check-label {
                order: 1;
                color: var(--whiteColor);
                margin-right: 48px;
                margin-top: 10px;
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }
            .form-check-input:checked, .form-check-input:checked:focus  {
                background-color: var(--primaryColor);
                border-color: var(--primaryColor);
            }
        }

    }
    .Form{
        .acoesEspecificacao{
            text-align: right;
        }
        h5 {
            color: var(--whiteColor);
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            display: inline-block;
            width: 100%;
            margin-bottom: 20px;
        }
        label {
            width: 100%;
            margin-bottom: 12px;
            position: relative;

            >span {
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
                width: 100%;
            }

            &.LabelAdd {
                position: relative;
            }

            &.labelInfo {
                i {
                    color: var(--primaryColor);
                    font-size: 18px;
                    position: relative;
                    top: -2px;
                }

                color: var(--whiteColor);
                font-size: 14px;
            }
        }
        label {
            width: 100%;
            margin-bottom: 12px;
            position: relative;

            >span {
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
                width: 100%;
                margin-bottom: 10px;
            }

            &.LabelAdd {
                position: relative;
            }

            &.labelInfo {
                i {
                    color: var(--primaryColor);
                    font-size: 18px;
                    position: relative;
                    top: -2px;
                }

                color: var(--whiteColor);
                font-size: 14px;
            }
        }

        .form-check-input:checked,
        .form-check-input:checked:focus {
            background-color: var(--primaryColor);
            border-color: var(--primaryColor);
        }

        .form-check-label {
            color: #727685;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            display: inline-block;
        }

        input:not([type=checkbox]) {
            border-radius: 6px;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            background: #373940;
            color: #FFF;
            display: inline-block;
            margin-bottom: 20px;
            height: 48px;
        }

        .acoes{
            text-align: right;
            padding: 0;
            padding-top: 30px;
            padding-bottom: 30px;
            .Btns{
                margin-left: 10px;
            }
        }

        .FormControl {
            width: calc(100% - 59px);
            display: inline-block;
            position: relative;

            &.Full {
                width: 100%;
            }

            i {
                position: absolute;
                color: #727685;
                top: 6px;
                right: 16px;
                font-size: 20px;
            }
        }

        textarea {

            &.wrongField {
                border-left: 7px var(--dangerColor) solid !important;
                border-radius: 5px;
            }

            border-radius: 6px;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            background: #373940;
            color: #FFF;
            display: inline-block;
            margin-bottom: 20px;
            height: 110px;
            width: 100%;
            padding: 12px 16px;
            resize: none;

            &::placeholder {
                font-weight: 600;
                font-size: 14px;
                color: #9B9B9B !important;
            }
        }

        .Dropdown {
            border-radius: 5px;
            background: #373940;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            width: 100%;
            height: 48px;
            line-height: 30px;
            padding-left: 10px;

            .p-icon.p-dropdown-trigger-icon.p-clickable {
                color: #B1B7D1;
            }

            .p-dropdown-trigger {
                position: relative;
            }

        }

        .p-dropdown {
            span {
                color: #FFF !important;
                opacity: 0.6 !important;
                font-weight: 400 !important;
            }

        }
        .FormControl {
            width: calc(100% - 59px);
            display: inline-block;
            position: relative;

            &.Full {
                width: 100%;
            }

            i {
                position: absolute;
                color: #727685;
                top: 6px;
                right: 16px;
                font-size: 20px;
            }
            input:not([type=checkbox]) {
                border-radius: 6px;
                border: 1px solid #373940 !important;
                box-shadow: none !important;
                background: #373940;
                color: #FFF;
                display: inline-block;
                margin-bottom: 20px;
                height: 48px;
            }
        }

    }
    .accordion{
        margin-top: 40px;
        .accordion-item{
            border-radius: 6px;
            border: 1px solid #373940;
            background: #191A1E !important;
            margin-bottom: 20px ;
            
            .accordion-button{
                h4{
                    color: #FFF;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
                i{
                    top: -4px; 
                }
            }
            .filterSearce {
                padding-top: 10px;
                padding-bottom: 10px;
                input {
                    border-radius: 43px;
                    border: 1px solid #373940;
                    background: #232428;
                    height: 36px;
                }
            }
            .accordion-header{
                padding: 20px 30px;
            }
            .accordion-body {
                padding: 20px 30px;
                background: #27292F;
                .cadastroItem{
                    font-family: var(--FontTertiary);
                    padding-top: 30px;
                    margin-top: 0px;
                    display: inline-block;
                    padding-bottom: 20px;
                    width: 100%;
                    .item{
                        display: inline-block;
                        border-radius: 6px;
                        border: 1px solid #4C4E55;
                        background: #373940;
                        width: 13.1%;
                        height: 150px;
                        margin-right: 15px;
                        text-align: center;
                        float: left;
                        padding: 15px;
                        position: relative;
                        margin-bottom: 15px;
                        i{
                            color: #FFF;
                        }
                        h3{
                            color: #FFF;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 22px;
                            line-height: normal;
                            padding: 10px 0 0;
                        }
                        h4{
                            color: #FFF;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            padding: 0;
                            margin-bottom: 0;
                        }
                        p{
                            color: #949494;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;  
                            width: 100%;
                            white-space: nowrap;
                            margin-top: 8px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        span{
                            color: #949494;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;  
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding-bottom: 6px;
                        }
                        .picture {
                            height: 100%;
                            border-radius: 6px;
                        }
                        .Acoes{
                            padding: 0;
                            margin-top: 14px;
                            position: absolute;
                            z-index: 999;
                            top:6px;
                            left: 6px;
                            margin: 0;
                            opacity: 0;
                            width: calc(100% - 12px);
                            height: calc(100% - 12px);
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            border-radius: 6px;
                            font-style: normal;
                            font-weight: 400;
                            font-family: inherit;
                            box-sizing: border-box;
                            transition: all ease-in 0.2s;
                            cursor: pointer !important;
                            color: #fff;
                            strong{
                                width: 100%;
                                display: inline-block;
                                padding-top: 6px;
                                font-size: 16px;
                            }
                            button{
                                background-color: rgba(135, 77, 249, 0.85)  !important;
                                border-radius: 10px;
                                height: 45%;
                                margin-bottom: 4%;
                                &:nth-child(1){
                                    margin-top: 0;
                                    background-color: rgb(249, 88, 77 , 0.85)  !important;
                                    &:hover{
                                        background-color: rgb(249, 88, 77 , 1)  !important;
                                    }
                                }
                                &:hover{
                                    background-color: rgba(135, 77, 249, 1)  !important;
                                }
                            }
                        }
                        .infos{
                            height: 120px;
                        }
                        &:hover{
                            .Acoes{
                                opacity: 1;
                               
                            }
                            
                        }
                    }
                    .addVariacao{
                        border-radius: 6px;
                        background: #1F2026;
                        border: 1px solid #4C4E55;
                        width: 13.5%;
                        height: 150px;
                        text-align: center;
                        display: flex;
                        float: left;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        transition: all ease-in-out 0.2s;
                        &:hover{
                            background: #373940;
                            cursor: pointer;
                            .Add{
                                background: var(--primaryColorHover) !important;
                                border: 1px solid var(--primaryColorHover) !important;
                                color: #FFF;
                              }
                        }
                    }
                }
                .editar{
                    text-align: right;
                    margin-top: 15px;
                    right: -40px;
                    position: relative;
                }
                .statusAdcionais{
                    text-align: right;
                    padding-top: 14px;
                    .form-check {
                        display: flex;
                        .form-check-label {
                            margin-right: 56px;
                            margin-top: 6px;
                        }
                    }
                    .Label.form-check.form-switch{
                        display: flex;
                        margin-top: 5px;
                        .form-check-input{
                            width: 40px;
                            height: 24px;
                            background-color: #373940;
                            border: 3px solid #373940;
                            order: 2;
                        }
                        label.form-check-label {
                            order: 1;
                            color: var(--whiteColor);
                            margin-right: 48px;
                            margin-top: 10px;
                            color: #727685;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            text-transform: uppercase;
                        }
                        .form-check-input:checked, .form-check-input:checked:focus  {
                            background-color: var(--primaryColor);
                            border-color: var(--primaryColor);
                        }
                    }
                    .Btns{
                        display: inline-block;
                        float: right;
                        margin-left: 20px;
                        border: 1px solid #55575C !important;
                    }
                }
                
            }
            .ButtonSecondaryLight{
                margin-right: 40px;
                padding: 6px 30px;
                top: -1px;
                position: relative;
                &:hover{
                    background: var(--primaryColor) !important;
                }
            }
            .prevStatus{
                border-radius: 20px;
                width: 14px;
                height: 14px;
                display: inline-block;
                
                right: 50px;
                top: 2px !important;
                position: absolute;
                &.danger{
                    background: var(--dangerColor);
                }
                &.sucess{
                    background: var(--publicColor);
                }
            }
            .Btns{
                &.ButtonIcon {
                    background: none;
                    border: none !important;
                    display: inline-block;
                    width: 100%;
                    height: 38px;
                    line-height: 25px;
                    border-radius: 60px;
                    color: #FFFFFF;
                    font-size: 23px;
                    padding: 0;
                    cursor: pointer !important;
                    &:hover{
                        color: var(--whiteColor);
                        cursor: pointer !important;
                        
                    }
                }
            }
        }
    }

}

@media (max-width: 768px) {

}