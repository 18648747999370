// MyArticles.scss

$font-family: 'Raleway', sans-serif;
$primary-color: #C8ECFC;
$secondary-color: #292D4D;
$dangerColor:#E18B83;

.wrongFieldLead{
    border-left: 7px var(--dangerColor) solid !important;
    border-radius: 6px !important;
}

.HeaderLeads{

  


    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #343434;
    i{
       color: #763EE7;
       font-size: 32px;
       float: left;
       margin-right: 10px;
       line-height: 0;
    }
    h2{
        color: #FFF;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; 
        line-height: 22px;
        span{
            color: #727685;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

}
.Leads {

        .myArticles {

            .row {
                .pageName::before {
                    content: "";
                    position: absolute;
                    left: 309px;
                    top: 94px;
                    width: 50px;
                    height: 5px;
                    background: linear-gradient(to right, #41BFF4, #4EE4D3);
                }

                .pageName {

                    h3 {
                        color: var(--secondary-color);
                        font-size: 33px;
                        font-weight: 700;
                        font-family: $font-family;
                    }
                }

                .cards {
                    width: 100%;
                    border: 1px solid #DADADA;
                    border-radius: 8px;
                    box-shadow: 0px 2px 6px rgba(240, 240, 240, 0.25);
                    background: #FFFFFF;
                    margin-bottom: 26px;
                }

                .List {
                    // background: #FFFFFF;
                }
            }
            
            .filter{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-top: 68px;
                margin-right: 0;
            }
        }
       
}
@media (max-width: 768px) {
    .page-myArticles{
        .myArticles{
            margin-bottom: 10px;
            .filter{
                margin-top: 0 !important;
                margin-bottom: 10px;
                .filterArticles{
                    position: relative;
                    width: 100%;
                    .icon{
                        border: 1px solid #DADADA;
                        border-radius: 8px;
                        box-shadow: 0px 2px 6px rgba(240, 240, 240, 0.25);
                        text-align: center;
                        padding: 9px 5px 0px 5px;
                        width: 48px !important;
                        display: block;
                        background: #FFF;
                        margin-bottom: 20px;
                        height: 42px;
                        left: 0;                    
                        span{
                            display: none;
                        }
                    }
                    .filterArticlesSelect{
                        width: 40%;
                        margin-left: 3%;
                        .filtro {
                            width: 100%;
                        }
                    }
                    .p-input-icon-right {
                        width: 57%;
                        .filter-input {
                            width: 100%;
                        }
                    }
                }
            }
            .List{
                .articleList {
                    .card {
                        padding: 20px;
                        .p-datatable-table{
                            display: flex;
                            .row{
                                display: none;
                            }
                        }
                        .p-datatable-thead{
                            display: none;
                        }
                        .p-datatable-tbody {
                            > tr{
                                padding-bottom: 20px;
                                border-left: 0 !important;
                                td{
                                    padding: 0;
                                    &:nth-child(1){
                                        white-space: inherit;
                                        overflow: inherit;
                                        text-overflow: inherit;
                                    }
                                    &:nth-child(2){
                                        width: 50% !important;
                                        display: inline-block;
                                        margin: 0;
                                        padding-left: 4px;
                                        border-bottom: none;
                                        background: url(./img/icon-user-list.svg) no-repeat  0 12px;
                                        padding-left: 24px;
                                        font-size: 13px;
                                    }
                                    &:nth-child(3){
                                        width: 50% !important;
                                        display: inline-block;
                                        margin: 0;
                                        text-align: right;
                                        padding-right: 4px;
                                        border-bottom: none;
                                        font-size: 13px;
                                    }
                                    &:nth-child(4){
                                        width: 33% !important;
                                        display: inline-block;
                                        margin: 0;
                                        border-bottom: none;
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        text-align: right;
                                        font-size: 14px;
                                    }
                                    &:nth-child(5){
                                        width: 100% !important;
                                        display: inline-block;
                                        margin: 0;
                                        border-bottom: none;
                                    }
                                }
                            }
                            .title-column{
                                width: 100% !important;
                                display: block;
                                padding: 10px 5px 10px 0;
                                font-size: 14px;
                                border-bottom: 1px solid #E1E1E1;
                            }
                            > tr {
                                position: relative;
                                display: block;
                                padding-top: 24px;
                            }
                            .waiting-button, .fix-button, .preview-button {
                                min-width: 100%;
                                margin: 0 0 5px 0;
                            }
                        }
                        .paginator {
                            justify-content: center;
                            > span:first-child{
                                display: none;
                            }
                            .p-inputwrapper-filled{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .HeaderLeads{
        padding-top: 20px;
    }
  
}