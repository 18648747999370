@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import "bootstrap/dist/css/bootstrap.min.css";


aside {
    position: fixed;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 98;
    .closeMenu{
        display: none;
    }
    a {
        color: blue;
        text-decoration: none;
        font-size: 22px;
        &.avatar {
            background: #000000;
            border-radius: 100px;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            overflow: hidden;
            border-radius: 50%;
            align-items: center;
            color: #FFFFFF;
            line-height: 16px;
            img{
                width: 40px;
                height: 40px;
                margin: 0 !important;
                padding: 0;
            }
        }
    }

    >div.container-header {
        transition: left 1s ease-in-out;
        background: url(./img/Effect-menu.png) no-repeat top right, linear-gradient(180deg, #1D1E20 0%, #131415 100%);
        height: 100%;
    }

    img.logo {
        width: 178px;
        height: 56px;
        display: flex;
        justify-content: center;
    }

    nav.navbar {
        width: 100%;
        display: flex;
        padding: 0 30px;
        ul {
            padding: 0px;
            width: 100%;

            li:not(li:last-child) {
                margin-bottom: 8px;
            }  
            li {
                display: flex;
                justify-content: space-around;
                height: auto;
                align-content: flex-start;
                flex-wrap: wrap;
                flex-direction: column;
                position: relative;
                &.desabilitado{
                    background: none !important;
                    a:hover, &.active:hover, &.open a{
                        background: none !important;
                        svg,
                        span {
                            color: var(--whiteColor);
                        }
                    }
                    span.breve {
                        border-radius: 25px;
                        background: #743BE4;
                        width: auto;
                        height: 16px;
                        padding: 4px 10px;
                        display: inline-block;
                        position: absolute;
                        right: 38px;
                        font-size: 10px !important;
                        top: 34px;
                        line-height: 8px !important;
                        color: #B4B7CF;
                        text-align: center;
                    }
                }
                a{
                    padding: 0 20px;
                    width: 100%;
                    transition: all ease-in 0.2s;
                    border-radius: 6px;
                    &.active{
                        span{
                            color: var(--whiteColor);
                        }
                    }
                    .number{
                        background: #B7544B;
                        display: inline-block;
                        color: var(--whiteColor);
                        border-radius: 40px;
                        width: 16px;
                        height: 16px;
                        text-align: center;
                        line-height: 14px !important;
                        float: right;
                        margin-top: 12px;
                        font-size: 13px;
                        &:hover{
                            color: var(--whiteColor);
                        }
                    }
                }

                a:hover, a.active {
                    svg,
                    span {
                        color: var(--whiteColor);
                    }
                }
                a:hover, &.active:hover, &.open a{
                    background: linear-gradient(90deg, #8A50FD 0%, #7038E0 100%);
                    border-radius: 6px;
                    svg,
                    span {
                        color: var(--whiteColor);
                    }
                }

                svg,
                span {
                    transition: color 1s;
                }

                svg {
                    color: #5b5f84;
                    margin-right: 18px;
                }

                span {
                    font-family: var(--FontDefault);
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 304.4%;
                    color: #B4B7CF;
                    svg{
                        display: none;
                    }
                }
                &.dropdown{
                    span{
                        svg{
                            float: right;
                            margin-right: 0;
                            margin-top: 15px;
                            display: inline-block;
                            font-size: 16px;
                            color: var(--whiteColor);
                        }
                    } 
                    ul{
                        border-radius: 5px;
                        background: #26272C;
                        padding: 10px 10px;
                        display: none;
  
                        li{
                            height: auto;
                            a{
                                background: none;
                                color: #B1B7D1;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px;
                                border: 1px solid transparent;
                                padding: 2px 10px 2px 20px;
                                svg{
                                    float: right;
                                    font-size: 20px;
                                    margin-right: 0;
                                    display: none;
                                }
                                &:hover, &.active{
                                    border-radius: 3px;
                                    border: 1px solid #42444D;
                                    background: #33343A;
                                    color: #B1B7D1;
                                    cursor: pointer;
                                    svg{
                                        display: block;
                                    }
                                }
                            }
                            &.active{
                                a{
                                    color: var(--whiteColor);
                                }
                            }
                            
                        }
                        
                    }
                    &.open{
                        ul{
                            display: inline-block;
                        }
                    }
                    &:hover{
                        cursor: pointer;
                    }
                }
            }

            li.active {
                a {
                    background: linear-gradient(90deg, #8A50FD 0%, #7038E0 100%);
                    border-radius: 6px;
                    svg,
                    span {
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }

    .user-card.profile {

        width: 235px;
        height: 80px;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        position: relative;
        z-index: 1;

        .profile {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            z-index: 2;
            position: relative;
            padding: 0 18px;
            border-radius: 7px;
            border: 1px solid #343434;
            background: linear-gradient(105deg, #232323 -17.25%, #141516 99.93%);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            .logout{
                border-radius: 2px;
                border: 1px solid #2C2D30;
                background: #26272C;
                color: #FFF;
                padding: 2px 6px 2px 8px;
                font-size: 16px;
                
            &:hover {
                cursor: pointer;
                svg {
                    color: var(--primaryColor);
                }
            }
            }

            span {
                color: white;
                font-family: 'Raleway';
                color: var(--whiteColor);
                font-size: 16px;
                font-weight: 600;
                position: relative;
                line-height: 20px;
                display: block;
                padding: 0 10px;
            }
        }



    }

}


@media (max-width: 768px) {
    header{
        display: block;
        background: #1D2039 url(../../../public/img/bg-topo-mobile.png) no-repeat center top / cover;
        width: 100%;
        height: auto;
        text-align: left;
        padding: 20px 0 0 0;
        line-height: 40px;
        border-radius: 0;
        position: relative;
        z-index: 9;
        min-height: 120px;
        img{
            width: 120px;
        }
        .menu-hamburger {
            position: relative;
            float: right;
            right: 0px;
            top: 0;
            color: #FFFFFF;
            z-index: 99999;
            z-index: 99999;
            border-radius: 9px;
            width: 40px;
            height: 40px;
        }
        .headerMobile{
            padding: 20px;
        }
    
    }
    aside {

        div.container-header {
            width: 100%;
            left: -100%;
            position: fixed;
            padding: 80px 60px 0;
            &.active{
                left: 0;
            }
        }
        .close{
            position: absolute;
            top: 10px;
            right: 20px;
            color: #FFF;
            font-size: 30px;
        }

        nav.navbar {
            margin: 0 0 6vh 0;
            display: flex;
            justify-content: left;
            padding: 0;
        }

        img.logo {
            margin: 2em 0 2em !important;
            margin: auto !important;
            margin-bottom: 40px !important;
        }

        hr {
            margin: 0;
        }


        .user-card.meta {
            margin: 0 auto 1em;
            width: calc(100% - 20px);
        }

        nav.navbar {
            margin: 0 0 1em 0px;
        }
        .closeMenu {
            display: block;
            position: absolute;
            color: #FFF;
            top: 40px;
            right: 30px;
        }
        .user-card.profile{
            margin: 0;
            width: 100%;
        }
    }

}

@media (max-width: 1024px) and (min-width: 768px) {
    aside {
        div.container-header {
            left: -100%;
            width: 290px;
            position: fixed;
        }
    }
}

@media (min-width: 768px) {

    aside {

        svg.menu-hamburger {
            display: none;
        }

        div.container-header {
            left: 0px;
            width: 290px;
            position: absolute;
        }

        nav.navbar {
            margin: 0 0 4vh 0;
        }

        img.logo {
            margin: 60px auto 57px;
        }

        hr {
            margin: 0 auto 6vh 
        }
    }

}

@media(min-width: 1024px) and (max-width: 1620px) {
    aside {
        div.container-header {
            width: 240px;
        }
        nav.navbar {
            margin: 0 0 4vh 0;
            padding: 0 20px;
            ul {
                li{
                    svg {
                        margin-right: 10px;
                    }
                }
            }
        }
        .user-card.profile {
            width: 214px;
        }
    }
}
@media(min-width: 1024px) and (max-width: 1280px) {
    aside {
        div.container-header {
            width: 200px;
            img.logo {
                width: 130px;
                height: auto;
            }
        }
        nav.navbar {
            margin: 0 0 4vh 20px;
            ul{
                li {
                    height: 36px;
                    span {
                        font-size: 13px;
                    }
                }
            }
        }
        a {
            font-size: 18px;
        }
        .user-card.profile {
            height: 55px;
            width: 172px;
            .profile {
                span {
                    font-size: 13px;
                    padding: 0 10px;
                }
            }
        }
        a.avatar {
            font-size: 11px;
            line-height: 14px;
            padding: 5px;
            line-height: 16px;
        }
        hr {
            width: 90%;
        }
    }
}