@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
.pdfcontrato{
    font-family: "Inter", sans-serif;
    width: 1082px;
    margin: auto;
    .assinatura-blocos{
        .ass{
            margin-top: 50px !important;
            margin-bottom: 10px;
        }
        .pessoa{
            margin: 0 !important;
        }

    }
    .data{
        margin-top: 50px !important;
    }
    .capa{
        background: url(img/capa.png) no-repeat center;
        height: 1530px;
        width: 1082px;
        h6{
            color: #5F5F5F;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 40px;
            text-transform: uppercase;
            padding-top: 1000px;
        }
    }
    .paginas{
        height: 100%;
        width: 1082px;
        padding: 60px;
        position: relative;
        margin-bottom: 100px;
        &.timbrado1{
            background: url(img/timbrado1.png) no-repeat center;
            h1{
                color: #FFF;
                margin-bottom: 40px;
            }
        }
        &.timbrado2{
            background: #FFF;
            p{
                color: #3f3f3f;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 20px;
            }
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                ul{
                    list-style: disc;
                    padding-left: 25px;
                    li{
                        margin-bottom: 10px;
                    }
                }
                li{
                    color: #6A6A6A;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal; 
                }
            }
        }
        &.timbrado3{
            background: url(img/timbrado3.png) no-repeat center;
            h1{
                color: #FFF;
            }
            h4{
                width: 60%;
            }
            h3{
                background: none;
                margin: 0;
                padding: 0;
            }
            &.lista{
                ul{
                    ul{
                        li{
                            list-style: disc;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
        .data{
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                padding-top: 309px;
                padding-bottom: 29px;
                border-bottom: 2px solid #2F2F2F;
                margin-bottom: 95px;
            }
            h3 {
                text-transform: uppercase;
                width: 64%;
                line-height: auto;
                margin-bottom: 40px;
                font-size: 32px;
            }
            strong{
                color: #5AB24C;
                font-style: normal;
                font-weight: 800;
            }
            p{
                color: #FFF;
                font-size: 23px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                line-height: 34px;
                br{
                    display: none;
                }
            }
        }
        h1{
            color: #464646;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 20px;
            strong{
                font-size: 55px;
            }
        }
        h6{
            color: #929292;
            text-align: left;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 40px;
        }
        h2{
            width: 100%;
            color: #000;
            text-align: left;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 30px;
        }
        .campos{
            .label{
                color: #80C174;
                text-align: left;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
            }
            .campo{
                color: #4A4A4A;
                text-align: left;
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                border-bottom: 1px solid #DADADA;
                margin-bottom: 25px;
            }
            .meio{
                width: 50%;
                display: inline-block;
            }
            .metade{
                width: 25%;
                display: inline-block;
            }
        }
        .valores{
            border-radius: 11px;
            background: url(img/selo.png) no-repeat center right, linear-gradient(90deg, #5AB24C 0%, #379C27 100%);
            padding: 40px; 
        }
        .valores{
            margin-top: 30px;
            h4{
                color: #183D12;
                text-align: left;
                font-size: 23.338px;
                font-style: normal;
                font-weight: 600;
                display: inline-block;
                width: 100%;
                margin-bottom: 10px;
            }
            h3{
                color: #FFF;
                text-align: left;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                display: inline-block;
                margin: 0;
                padding: 0;
                background: none;
                span{
                    font-size: 20px;
                }
            }
            span{
                color: #FFF;
                text-align: center;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                padding-left: 20px;
            }
            hr{
                border: 2px solid #7BD16E;
                background: none;
                color: none;
                width: 70%;
            }
        }
        h4{
            color: #929292;
            text-align: left;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 40px; 
        }
        h5{
            color: #5AB24C;
            font-size: 21px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-top: 30px;
        }
        ul{
            li{
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 30px;
            }
            &.number{
                list-style: decimal;
            }
        }
        h3{
            color: #000000;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 10px 0 0;
            margin: 10px 0;
        }
        .assinatura{
            bottom: 150px;
            position: absolute;
            .parte1{
                color: #000;
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
            }
            .parte2{
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
            }
        }
        .protecao{
            padding-top: 30px;
            .switch{
                .Label.form-check.form-switch{
                    margin-left: 0;
                    .form-check-input{
                        width: 40px;
                        height: 24px;
                        background-color: #5a5c62;
                        border: 3px solid #5a5c62;
                        order: 2;
                       
                    }
                    label.form-check-label {
                        order: 1;
                        color: var(--whiteColor);
                        margin-left: 10px;
                        margin-top: 10px;
                        color: #727685;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-transform: uppercase;
                    }
                    .form-check-input:checked, .form-check-input:checked:focus  {
                        background-color: var(--primaryColor);
                        border-color: var(--primaryColor);
                    }
                }
            }
            table{
                background: #595959;
                width: 100%;
                thead{
                    text-align: center;
                    tr{
                        th{
                            color: #FFF;
                            font-size: 14px;
                            padding: 20px;
    
                        }
                    }
                }
                tbody{
                    background: #373940;
                    tr{
                        td{
                            border-right: 1px solid #595959;
                            border-bottom: 1px solid #595959;
                            text-align: center;
                            color: #FFF;
                            font-size: 12px;
                            padding: 10px;
                            .form-check-label{
                                width: auto !important;
                                color: #FFF !important;
                            }
                        }
                        &:last-child{
                            border-left: 1px solid #595959;
    
                        }
                        &:nth-child(odd){
                            background: #47494f;
                        }
                    }
                }
            }
    
        }
    }  
    .verso{
        background: url(img/verso.png) no-repeat center;
        height: 1530px;
        width: 1082px;
        h1{
            color: #5AB24C;
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 900;
            padding-top: 1320px;
        }
        p{
            text-align: center;
            width: 75%;
            margin: auto;
            color: #FFF;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
        }
    } 
    .barPrint {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background:  linear-gradient(180deg, #1D1E20 0%, #131415 100%);
        padding: 20px;
        button{
            margin: 0 10px;
        }
    
    }
}

@media (max-width: 768px) {
    html {
        -webkit-text-size-adjust: 100%;
    }
    .capa, .paginas, .verso {
        zoom: 0.3; /* Primeira tentativa com zoom */
        margin: auto;
        overflow: hidden;
    }

    .pdf {
        width: 100%;
        font-size: 12px;
    }
}
@supports (-webkit-touch-callout: none) {
    /* Estilos aplicados apenas em iOS */
    .capa {
        h6 {
            font-size: 12px;
            margin-top: 10px;
        }
    }
    .paginas {
        p {
            font-size: 12px;
        }
        ul {
            li {
                font-size: 8px;
            }
        }
        .data {
            h3 {
                width: 80%;
                margin-bottom: 20px;
                font-size: 10px;
            }
            p {
                font-size: 8px;
            }
        }
        h1 {
            font-size: 14px;
            margin-bottom: 2px;
            strong{
                font-size: 18px;
            }
        }
        h6{
            font-size: 8px;
            padding-top: 0;
        }
        h2 {
            padding: 5px 10px;
            width: 100%;
            border-left: 4px solid #5AB24C;
            font-size: 7px;
            margin-bottom: 20px;
        }
        .campos {
            .label, .campo {
                font-size: 7px;
            }
        }
        .valores{
            h4 {
                font-size: 6px;
            }
            h3 {
                font-size: 10px;
                margin-bottom: 0;
                span{
                    font-size: 6px;
                }
            }
            span{
                font-size: 6px;
            }
            hr{
                margin: 2px 0;
                padding: 0;
            }
        }
        h4 {
            font-size: 6px;
        }
        h3 {
            font-size: 10px;
        }
        h5 {
            font-size: 7px;
        }
        ul{
            margin: 0;
            padding: 0 0 0 40px;
            ul{
                margin: 0;
                padding: 0;
                li{
                    font-size: 6px;
                }
            }
        }
        .protecao{
            transform: scale(0.44);
            transform-origin: 0 0;
        }
        &.timbrado2 {
            p {
                font-size: 7px;
            }
            ul {
                li {
                    font-size: 6px;
                }
            }
            .ajuste-mobile-h5{
                margin-top: -720px;
            }
            h5{
                margin-bottom: 0;
            }
        }
        .assinatura {
            .parte1 {
                font-size: 6px;
            }
            .parte2 {
                font-size: 8px;
            }
        }
    }
    .verso{
        p {
            font-size: 9px;
        }
        h1 {
            font-size: 10px;
            padding-top: 1300px;
        }
    } 
}

@media print {
    body {
        margin: 0; /* Remove as margens do body */
    }
    .capa, .paginas, .verso {
        width: 1082px;  /* Define a largura original */
        height: 1530px; /* Define a altura original */
        zoom: 1;        /* Remove qualquer escala aplicada */
        margin: 0;
        page-break-after: always;
        width: 100vw; /* Usa 100% da largura da viewport */
        height: 100vh; /* Usa 100% da altura da viewport */
        box-sizing: border-box; /* Certifica que padding e border são incluídos no tamanho total */
    }
}
@page {
    size: auto; /* Ajusta a página automaticamente */
    margin: 0; /* Remove as margens da página */
}