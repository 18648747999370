// Alert.scss


.alert-notification {
    display: block;
    width: auto;
    position: absolute;
    float: right;
    top: 0;
    right: 0;

    .background {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: #202125;
        border-radius: 50%;
        border: 1px solid #34353D;
        &::after{
            content: "";
            width: 51px;
            height: 85px;
            background: transparent;
            position: absolute;
            right: 11px;
            top: 0;
        }
    }

    .message-box {
        position: absolute;
        z-index: 2;
        width: 235px;
        height: 109px;
        top: 10px;
        right: 50px;
        padding: 5px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid var(--primaryColor);
        border-radius: 20px 5px 20px 5px;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);

        .message-content {
            margin: 0 auto;

            p {
                color: #9696AA;
                font-family: var(--FontDefault);
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 135.4%;
                display: flex;
                justify-content: center;
                margin: 0;

                span {
                    color: var(--primaryColor);
                    font-family: var(--FontDefault);
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 135.4%;
                    text-align: center;
                    text-decoration-line: underline;
                    margin-left: 4px;
                }
            }

            .button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                width: 80%;
                height: 21px;
                background: #E6E6EA;
                border: none;
                border-radius: 2px;
                font-family: var(--FontDefault);
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 304.4%;
                color: #767676;
                margin-top: 10px;

                &:hover {
                    transition: ease-in 0.2s;
                    background: #DADADA;
                }
            }

        }
    }
    .status {
        background-color: var(--primaryColor);
        position: absolute;
        width: 16px;
        height: 16px;
        color: #FFF;
        text-align: center;
        font-size: 12px;
        line-height: 14px;
        border-radius: 20px;
        top: -4px;
        right: -2px;
    }
}