// MyArticles.scss

$font-family: 'Raleway', sans-serif;
$primary-color: #C8ECFC;
$secondary-color: #292D4D;

.CampraVeiculos {
    .HeaderVeiculos {
        position: relative;
        padding-bottom: 20px;
        border-bottom: 1px solid #343434;

        i {
            color: #763EE7;
            font-size: 32px;
            float: left;
            margin-right: 10px;
            line-height: 0;
        }

        h2 {
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            line-height: 22px;

            span {
                color: #727685;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

    }

    .actionsVeiculos {
        text-align: right;
    }

    .listVeiculos {
        border-radius: 8px;
        background: linear-gradient(180deg, #222328 0%, #202124 100%);
        padding: 30px;

        .Veiculo {
            border-radius: 8px;
            border: 1px solid #2C2D30;
            background: #26272C;
            padding: 16px 20px;
            margin-bottom: 30px;

            &:hover {
                border-radius: 8px;
                border: 1px solid #2C2D30;
                background: linear-gradient(180deg, #373940 0%, #373940 100%);
                box-shadow: -1px 2px 9.8px 0px rgba(29, 30, 34, 0.26);

                h4 {
                    background: #2E3036;
                    
                }
            }

            .imagem {
                .thumbnail {
                    width: 100%;
                    object-fit: cover;
                    max-height: 120px;
                }
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 120px;
                background: #cdcdcd;
                border-radius: 5px;
                margin-bottom: 20px;
                position: relative;

                span {
                    border-radius: 41.85px;
                    border: 1px solid #167230;
                    background: #8FD1A2;
                    width: auto;
                    display: inline-block;
                    padding: 2px 8px;
                    color: #167230;
                    left: 10px;
                    top: 10px;
                    position: absolute;

                    i {
                        width: 8px;
                        height: 8px;
                        flex-shrink: 0;
                        background: #278F44;
                        display: inline-block;
                        border-radius: 20px;
                        margin-right: 4px;
                        position: relative;
                        top: 0px;
                    }

                    &.danger {
                        border: 1px solid #813933;
                        background: #E18B83;
                        color: #813933;

                        i {
                            background: #813933;
                        }
                    }

                    &.warning {
                        border: 1px solid #785E00;
                        background: var(--warningColor);
                        color: #785E00;

                        i {
                            background: #785E00;
                        }
                    }
                    &.sold {
                        border: 1px solid #695201;
                        background: var(--warningColor);
                        color: #695201;

                        i {
                            background: #695201;
                        }
                    }
                }
            }

            h2 {
                color: #D9D9D9;
                font-size: 18px;
                font-style: normal;
                height: 20px;
                font-weight: 600;
                line-height: normal;
            }

            h6 {
                color: #5C5D66;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            span {
                color: #5C5D66;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                width: 100%;
                display: block;
                text-transform: uppercase;
                margin-bottom: 4px;
            }

            hr {
                border-bottom: 1px solid #36373E;
                width: 1005;
            }

            h3 {
                color: #D9D9D9;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                width: 50%;
                display: inline-block;
            }

            h4 {
                color: #B1B7D1;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border-radius: 45px;
                border: 1px solid #373940;
                background: #232428;
                padding: 4px 10px;
                width: 50%;
                display: inline-block;
                text-align: center;
                font-family: var(--FontTertiary) !important;

                i {
                    font-size: 16px;
                }
            }

            button {
                margin-top: 16px;
            }
            .ButtonIcon {
                margin-left: 10px;
                float: right;
            }
            .acoes{
                display: flex;
                .ButtonPrimary{
                    text-transform: uppercase;
                    &:first-child {
                        flex-grow: 1;
                    }
                }
    
            }
        }
    }
}
@media(min-width: 1024px) and (max-width: 1620px) {
    .Veiculos{
        .listVeiculos {
            .Veiculo {
                h4 {
                    font-size: 12px;
                    padding: 4px 6px;
                    width: 42%;
                }
                h3 {
                    font-size: 14px;
                    width: 58%;
                    font-family: var(--FontTertiary);
                }
                h2 {
                    font-size: 15px;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .Veiculos {
        .listVeiculos {
            padding: 12px 16px !important;
            .Veiculo {
                padding: 10px 6px;
                margin-bottom: 10px;
                .imagem {
                    height: 84px;
                    margin-bottom: 10px;
                }
                h2 {
                    font-size: 12px;
                    height: 45px;
                }
                h6 {
                    font-size: 10px;
                }
                h3 {
                    font-size: 14px;
                    width: 100%;
                }
                .acoes {
                    display: inline-block;
                    text-align: center;
                }
                .Btns.ButtonSecondary, .Btns.ButtonPrimary {
                    padding: 8px 10px;
                    font-size: 10px;
                    margin: 6px 0 0 0;
                    width: 100%;
                }
                hr {
                    margin: 8px 0;
                }
                .ButtonIcon {
                    margin-left: 2px;
                    margin-right: 2px;
                    float: inherit;
                }
                > a{
                    display: none;
                }
                span {
                    font-size: 8px;
                    left: 4px !important;
                    top: 4px !important;
                }
            }
            .row{
                > div {
                    padding: 0 5px;
                }
            }
        }
        .HeaderVeiculos{
            padding-bottom: 10px !important;
            i {
                font-size: 32px;
                margin-bottom: 20px;
            }
            h2 {
                font-size: 18px;
                line-height: 34px;
                span{
                    display: none;
                }
            }
        }
    }
}