// NewPassword.scss
.page-login{
  .row {
    #logo {
      background: url(../../pages/Login/img/img-login.png) no-repeat center / cover;
      padding: 0;
    }
    #forms {
      padding: 0;
      border-radius: 0;
      .form {
        background: url(../../pages/Login/img/Ellipse-2.png) no-repeat top right, url(../../pages/Login/img/Ellipse-1.png) no-repeat bottom left;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        border-radius: 0;
        form{
          min-width: 446px;
          > div{
            position: relative;
          }
        }
        .title {
          margin-bottom: 30px;
          font-weight: 500;
          font-size: 25px;
          line-height: 38px;
          text-align: left;
          min-width: 446px;
          i{
            margin-bottom: 20px;
            display: inline-block;
          }
          h3{
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          h4 {
            color: #8C52FF;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.19px;
            text-transform: uppercase;
          }
          p{
            color: #FFF;
            font-size: 18px;
          }
        }
  
        .icon {
          position: absolute;
          color: var(--primaryColor);
          padding: 1px 15px 4px 15px;
          font-size: 20px;
          right: -5px;
          border-left: 1px solid #616161;
          margin-top: 10px;
        }
  
        .iconWrong {
          color: var(--dangerColor);
        }
  
        .show-password {
          top: 20px;
          right: 95px;
          color: var(--primaryColor);
        }
  
        input {
          padding: 14px 40px 16px 24px;
          background: none;
          border: 1px solid #4D4D4D;
          border-radius: 5px;
          width: 446px;
          margin-bottom: 24px;
          color: #979797;
        }
  
        input::placeholder {
          font-size: 14px;
          color: #979797;
        }
  
        input:focus {
          outline: none;
          background: linear-gradient(90deg, #393939 0%, #212121 108.64%);
        }
  
        input.usernameWrong {
          border-style: solid;
          border-color: var(--dangerColor);
          border-width: 1px 1px 1px 8px;
        }
  
        input.passwordWrong {
          border-style: solid;
          border-color: var(--dangerColor);
          border-width: 1px 1px 1px 8px;
        }
  
        .passwordRecovery {
          cursor: pointer;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          text-decoration-line: underline;
          color: #7B7B7B;
          margin: 30px 0 0  0;
          font-family: var(--FontSecondary);
        }
  
        button {
          width: 446px;
          color: #fff;
          border-radius: 3px;
          background: var(--primaryColor);
          box-shadow: 0px 4px 3px 0px rgba(217, 217, 217, 0.2);
          text-transform: uppercase;
          padding: 16px;
        }
  
        button:hover {
          background: var(--secondaryColor);
          transition: background-color 0.2s ease-in-out;
        }
  
        .error-message{
          color: var(--dangerColor);
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          font-size: 13px;
        }
      }
    }

    


    @media(max-width:960px) and (min-width: 690px) {

      #logo {
        width: 45%;
      }

      #forms {
        width: 55%;
      }
    }

    @media(max-width:770px) {
      input{
        width: 300px !important;
        margin-left: 20px;
      }
      .icon{
        margin-left: 30px !important;
      }
      button{
        width: 300px !important;
        margin-left: 20px;
      }
      .passwordRecovery{
        margin-left: 110px !important;
      }
      #logo {
        display: none;
      }

      #forms {
        width: 100%;
      }
    }
  }
    .title {
      display: flex;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 25px;
      line-height: 38px;

      h4 {
        color: #000000;
      }
    }

    .icon {
      position: absolute;
      color: var(--primaryColor);
      margin: 5px 10px;
    }

    .newPassword {
      padding: 5px;
      background: #FFFFFF;
      border: 1px solid #E1E5FF;
      border-radius: 5px;
      width: 250px;
      margin-bottom: 10px;
      padding-left: 40px;
    }

    input::placeholder {
      color: #42496D;
    }

    input:focus {
      outline: none;
    }

    input.confirmPassword{
      border-style: solid;
      border-color: var(--dangerColor);
      border-width: 2px 2px 2px 8px;
    }

    // icon.lockConfirm{
    //   color: $quaternaryColor;
    // }

    .savePassword {
      width: 250px;
      padding: 10px 20px;
      background: var(--primaryColor);
      border-radius: 4px;
      color: #fff;
      border: none;
      cursor: pointer;      
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
      margin-top: 30px;
    }

    .savePassword:hover {
      background: var(--secondaryColor);
      transition: background-color 0.2s ease-in-out;
    }
    #logo{
      height: 100vh;
    }
    #forms{
      height: 100vh;
    }
  }