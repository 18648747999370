// Novos Veiculos


.Variacoes {
    .NovoVariacoes{
        padding: 60px 50px 40px;
        border-radius: 8px;
        background: linear-gradient(180deg, #222328 0%, #202124 100%);
        margin-top: 40px;
        margin-bottom: 40px;
        h3{
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            padding-bottom: 16px;
            border-bottom: 1px solid #373940;
            position: relative;
            margin-bottom: 20px;
            &::before{
                content: "";
                width: 48px;
                height: 6px;
                background: #743BE4;
                position: absolute;
                left: 0;
                bottom: -3px;
            }
        }
        label{
            width: 100%;
            margin-bottom: 12px;
            > span{
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
                width: 100%;
            }
        }
        input:not([type=checkbox]):not([type=radio]){
            border-radius: 6px;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            background: #373940;
            color: #FFF;
            display: inline-block;
            margin-bottom: 20px;
            height: 48px;
        }
        .FormControl{
            width: calc(100%);
            display: inline-block;
            position: relative;
            i{
                position: absolute;
                color: #727685;
                top: 10px;
                right: 16px;
                font-size: 20px;
            }
        }
        textarea{
            border-radius: 6px;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            background: #373940;
            color: #FFF;
            display: inline-block;
            margin-bottom: 20px;
            height: 110px;
            width: 100%;
            padding: 12px 16px;
            resize: none;
            &::placeholder {
                font-weight: 600;
                font-size: 14px;
                color: #9B9B9B !important;
            }
        }
        .Dropdown{
            border-radius: 5px;
            background: #373940;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            width: 100%;
            height: 48px;
            line-height: 30px;
            padding-left: 10px;
            .p-icon.p-dropdown-trigger-icon.p-clickable{
                color: #B1B7D1;
            }
            .p-dropdown-trigger{
                position: relative;
            }

        }
        .p-dropdown {
            span{
                color: #FFF !important;
                opacity: 0.6 !important;
                font-weight: 400 !important;
            }

        }
        .Label{
            color: #727685;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            display: inline-block;
            line-height: 24px;
            margin-right: 20px;
            .form-check-label{
                margin-left: 10px;
            }  
        }
        .form-check-input {
            border: 3px solid #464850;
            width: 18px;
            height: 18px;
            background-color: #464850;
        }
        .form-check-input:checked {
            background-color: #743BE4;
            background-image: none;
        }
        .dropzone{
            p{
                font-size: 9px;
                font-weight: bold;
            }
        }
        .DatePicker{
            display: flex;
            margin-bottom: 20px;
            z-index: 999;
            .react-datepicker-wrapper{
                display: inline-block;
                float: left;
                margin-right: 14px !important;
                width: calc(50% - 8px);
                &:nth-child(2), &:nth-child(3){
                    margin-right: 0 !important;
                }
                svg{
                    padding: 14px;
                    fill: var(--whiteColor);
                }
                input{
                    padding-left: 40px;
                    width: 100%;
                }
            }
            .react-datepicker__tab-loop{
                order:3;
            }
        }
        .Btns{
            margin-left: 20px;
        }
        .text-right{
            text-align: right;
        }
        .ImagesUploader{
            position: relative;
            display: inline-block;
            margin-right: 20px;
            width: 200px;
            float: left;
            z-index: 1;
            .iu-label{
                display: none;
            }
            position: relative;
            .iu-loadContainer{
                color: inherit;
                width: 200px;
                height: 140px;
                display: inline-block;
                border-radius: 6px;
                border: 2px dashed #373940;
                background: none;
                .iu-dropzone{
                    border: none !important;
                    border-radius: 6px !important;
                    &:hover{
                        background: rgba(39,41,47, 0.2);
                        border-radius: 6px !important;
                    }
                }
                span{
                    border-radius: 6px !important;
                    z-index: 2;
                    span{
                        display: none;
                    }
                }
            }
            > span{
                width: 100%;
                height: 136px;
                position: absolute;
                top: 8px;
                left: 0;
                z-index: -1;
                text-align: center;
                background: #191A1E;
                i{
                    color: #555A67;
                    font-size: 30px;
                    width: 100%;
                    display: inline-block;
                    margin-top: 16px;
                }
                p{
                    color: #555A67;
                    font-size: 9px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                h6{
                    color: #FFF;
                    font-size: 13.02px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
            .form-check-label{
                font-size: 11px;
                text-transform: initial;
                letter-spacing: -0.1px;
                font-weight: 400;
            }
            .Label{
                text-align: center
            }
        }
        .listagem{
            border-radius: 6px;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            background: #373940;
            color: #FFF;
            display: inline-block;
            margin-bottom: 20px;
            height: 48px;
            width: 100%;
            color: #FFF !important;
            
            .tag {
                float: left;
                border-radius: 41.85px;
                border: 0.93px solid #373940;
                background: #373940;
                padding: 5px 10px 5px 18px;
                color: #FFF !important;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-right: 10px;
                display: inline-block;
                margin-bottom: 6px;
                background: linear-gradient(90deg, #894FFC 50%, #7139E1 106.74%);
                margin: 6px;
                i{
                    position: relative;
                    top: -2px;
                    font-style: normal;
                    font-size: 12px;
                    margin-left: 10px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                &:hover{
                    color: #FFF;
                }
                
            }
            .form-control{
                float: left;
                width: auto;
                max-width: 200px;
                color: #FFF !important;
                height: 46px !important;
                margin-bottom: 0;
                &::placeholder{
                    color: #9B9B9B !important;
                    font-weight: 600;
                    font-size: 14px;
                }
            }
            
        }

    }
    .HeaderVariacoes{
        position: relative;
        padding-bottom: 20px;
        border-bottom: 1px solid #343434;
        i{
           color: #763EE7;
           font-size: 32px;
           float: left;
           margin-right: 10px;
           line-height: 0;
        }
        h2{
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; 
            line-height: 22px;
            span{
                color: #727685;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    
    }
}
@media (max-width: 768px) {

}