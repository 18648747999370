.ModalProduto{
    .modal-content{

        input:not([type=checkbox]):not([type=radio]){
            border-radius: 6px;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            background: #373940;
            color: #FFF;
            display: inline-block;
            margin-bottom: 20px;
            height: 48px;
            &::placeholder {
                font-weight: 600;
                font-size: 14px;
                color: #9B9B9B !important;
            }
        }
        .FormControl{
            width: calc(100%);
            display: inline-block;
            position: relative;
            i{
                position: absolute;
                color: #727685;
                top: 10px;
                right: 16px;
                font-size: 20px;
            }
            
        }
        textarea{
            border-radius: 6px;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            background: #373940;
            color: #FFF;
            display: inline-block;
            margin-bottom: 0px;
            height: 110px;
            width: 100%;
            padding: 12px 16px;
            resize: none;
            &::placeholder {
                font-weight: 600;
                font-size: 14px;
                color: #9B9B9B !important;
            }
        }
        .Dropdown{
            border-radius: 5px;
            background: #373940;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            width: 100%;
            height: 48px;
            line-height: 30px;
            padding-left: 10px;
            .p-icon.p-dropdown-trigger-icon.p-clickable{
                color: #B1B7D1;
            }
            .p-dropdown-trigger{
                position: relative;
            }

        }
        .p-dropdown {
            span{
                color: #FFF !important;
                opacity: 0.6 !important;
                font-weight: 400 !important;
            }

        }
        .checkboxOne{
            .Label{
                color: #727685;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
                line-height: 24px;
                margin-right: 20px; 
                margin-bottom: 10px;
                .form-check-input {
                    border: 3px solid #464850;
                    width: 18px;
                    height: 18px;
                    background-color: #464850;
                }
                .form-check-input:checked {
                    background-color: #743BE4;
                    background-image: none;
                }
            }
            &.melhorescolha{
                text-align: right;
                .form-check-label{
                    padding-right: 27px;
                }
                input{
                    float: inline-end;
                    z-index: 1;
                    position: relative;
                }
            }
        }

        .checkbox{
            .Label{
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
                line-height: 24px;
                margin-right: 20px;
                .form-check-label{
                    margin-left: 10px;
                }  
            }
            .form-check-input {
                border: 3px solid #464850;
                width: 18px;
                height: 18px;
                background-color: #464850;
            }
            .form-check-input:checked {
                background-color: #743BE4;
                background-image: none;
            }
        }
        .DatePicker{
            display: flex;
            margin-bottom: 20px;
            z-index: 999;
            .react-datepicker-wrapper{
                display: inline-block;
                float: left;
                margin-right: 14px !important;
                width: calc(50% - 8px);
                &:nth-child(2), &:nth-child(3){
                    margin-right: 0 !important;
                }
                svg{
                    padding: 14px;
                    fill: var(--whiteColor);
                }
                input{
                    padding-left: 40px;
                    width: 100%;
                }
            }
            .react-datepicker__tab-loop{
                order:3;
            }
        }
        .accordion {
            padding-top: 20px;
            padding-bottom: 20px;
            .accordion-item {
                .accordion-button {
                    margin-left: 0 !important;
                }
            }
        }
    }
}