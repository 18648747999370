// MyArticles.scss

$font-family: 'Raleway', sans-serif;
$primary-color: #C8ECFC;
$secondary-color: #292D4D;


.Dados {
    .HeaderDados{
        position: relative;
        border-bottom: 1px solid #343434;
        i{
           color: #763EE7;
           font-size: 32px;
           float: left;
           margin-right: 10px;
           line-height: 0;
        }
        h2{
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; 
            line-height: 22px;
            span{
                color: #727685;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    
    }
    .actionsDados{
        text-align: right;
    }
    .tableDados{
        border-radius: 8px;
        background: linear-gradient(180deg, #222328 0%, #202124 100%);
        padding: 30px 60px;
        margin-top: 30px;
        .HeaderForm {
            position: relative;
            padding-bottom: 20px;
            text-align: left;
            border-bottom: 1px solid #373940;
            margin-bottom: 30px;
            margin-top: 60px;

            &::before {
                content: "";
                background: #743BE4;
                height: 6px;
                width: 130px;
                position: absolute;
                bottom: -3px;
                left: 0;
            }

            i {
                color: #763EE7;
                font-size: 32px;
                margin-right: 6px;
                line-height: 0;
            }

            h2 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                line-height: 22px;
                text-transform: uppercase;
                margin-top: 10px;

                span {
                    color: #727685;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

        }
    }
    .FormDados {
        h5 {
            color: var(--whiteColor);
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            display: inline-block;
            width: 100%;
            margin-bottom: 20px;
        }
        label {
            width: 100%;
            margin-bottom: 12px;
            position: relative;

            >span {
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
                width: 100%;
            }

            &.LabelAdd {
                position: relative;
            }

            &.labelInfo {
                i {
                    color: var(--primaryColor);
                    font-size: 18px;
                    position: relative;
                    top: -2px;
                }

                color: var(--whiteColor);
                font-size: 14px;
            }
        }
        .FormControl {
            width: calc(100% - 59px);
            display: inline-block;
            position: relative;

            &.Full {
                width: 100%;
            }

            i {
                position: absolute;
                color: #727685;
                top: 6px;
                right: 16px;
                font-size: 20px;
            }
            input:not([type=checkbox]) {
                border-radius: 6px;
                border: 1px solid #373940 !important;
                box-shadow: none !important;
                background: #373940;
                color: #FFF;
                display: inline-block;
                margin-bottom: 20px;
                height: 48px;
            }
        }
        .acoes{
            text-align: center;
            padding-top: 30px;
            padding-bottom: 30px;
            .Btns{
                margin: 0 10px;
            }
        }
        .Dropdown {
            border-radius: 5px;
            background: #373940;
            border: 1px solid #373940 !important;
            box-shadow: none !important;
            width: 100%;
            height: 48px;
            line-height: 30px;
            padding-left: 10px;

            .p-icon.p-dropdown-trigger-icon.p-clickable {
                color: #B1B7D1;
            }

            .p-dropdown-trigger {
                position: relative;
            }

        }
        .p-dropdown {
            span {
                color: #FFF !important;
                opacity: 0.6 !important;
                font-weight: 400 !important;
            }

        }
        .Btns.Add, .Btns.Remove {
            float: right;
            margin-top: 5px;
        }
        .statusDoisFatores {
            position: relative;
            margin-bottom: 14px;
            .Label.form-check.form-switch{
                margin-left: 0;
                .form-check-input{
                    width: 40px;
                    height: 24px;
                    background-color: #373940;
                    border: 3px solid #373940;
                    order: 2;
                   
                }
                label.form-check-label {
                    order: 1;
                    color: var(--whiteColor);
                    margin-left: 10px;
                    margin-top: 10px;
                    color: #727685;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                }
                .form-check-input:checked, .form-check-input:checked:focus  {
                    background-color: var(--primaryColor);
                    border-color: var(--primaryColor);
                }
            }

        }
    }
}
@media (max-width: 768px) {
    .Dados{
        .tableDados {
            padding: 30px 20px;
            .HeaderForm {
                margin-top: 30px;
            }
        }
    }
}