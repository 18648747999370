// Novos Veiculos

$dangerColor: #E18B83;
$dangerColorHover: #C61616;
$warningColor: #E3B100;
$publicColor: #1DCC43;
$publicColorHover: #14AD36;


.Proposta {

    .dropdownWrongField {
        border-radius: 5px;
    }

    .wrongField {
        border-left: 7px var(--dangerColor) solid !important;
        border-radius: 5px;
    }

    .noMarginButtonInput {
        margin-bottom: 0 !important;
    }
    .NavVeiculos {
        ul {
            width: max-content;
            list-style: none;
            margin: 0 auto;
            padding: 0;
            text-align: center;
            position: relative;
            z-index: 1;

            &::before {
                content: "";
                position: absolute;
                top: 26px;
                left: 60px;
                width: calc(100% - 120px);
                border-bottom: 2px dotted #373940;
                z-index: -1;
            }

            li {
                list-style: none;
                display: inline-block;
                margin: 0;
                padding: 0;
                color: #FFF;
                min-width: 120px;




                a {


                    &.disabled-phase {

                        &:hover {
                            cursor: auto;

                            i {
                                display: inline-block;
                                width: 52px;
                                height: 52px;
                                background: #232428;
                                border: 1px solid #555A67;
                                border-radius: 100px;
                                margin-bottom: 10px;
                                line-height: 48px;
                                font-size: 20px;
                                color: #555A67;
                            }
                        }

                        cursor: auto;

                        i {
                            display: inline-block;
                            width: 52px;
                            height: 52px;
                            background: #232428;
                            border: 1px solid #555A67;
                            border-radius: 100px;
                            margin-bottom: 10px;
                            line-height: 48px;
                            font-size: 20px;
                            color: #555A67;
                        }
                    }

                    i {
                        display: inline-block;
                        width: 52px;
                        height: 52px;
                        background: #232428;
                        border: 1px solid #555A67;
                        border-radius: 100px;
                        margin-bottom: 10px;
                        line-height: 48px;
                        font-size: 20px;
                        color: #555A67;
                    }

                    span {
                        width: 100%;
                        display: inline-block;
                        color: #D9D9D9;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    &:hover,
                    &.active {
                        cursor: pointer;

                        i {
                            background: linear-gradient(136deg, #743BE4 24.43%, #743BE4 171.17%);
                            border: 1px solid #743BE4;
                            color: #FFFFFF;
                        }
                    }


                }
            }
        }
    }

    .Novo {
        padding: 40px 30px 40px;
        border-radius: 8px;
        background: linear-gradient(180deg, #222328 0%, #202124 100%);
        margin-top: 40px;
        margin-bottom: 40px;
        .naoEncontrol{
            color: var(--primaryColor);
            float: right;
            margin-top: -20px;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1px;
            z-index: 1;
            position: relative;
            &:hover{
                cursor: pointer;
                color: #9c70f2;
            }
        }

        .HeaderNovo {
            position: relative;
            padding-bottom: 20px;
            text-align: left;
            border-bottom: 1px solid #373940;
            margin-bottom: 30px;
            margin-top: 60px;

            &::before {
                background: #743be4;
                bottom: -3px;
                content: "";
                height: 6px;
                left: 0;
                margin-left: 0;
                position: absolute;
                width: 130px;
            }

            i {
                color: #763EE7;
                font-size: 32px;
                margin-right: 6px;
                line-height: 0;
            }

            h2 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                line-height: 22px;
                text-transform: uppercase;
                margin-top: 10px;

                span {
                    color: #727685;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

        }

        .FormNovo {
            .diferenciais {
                position: relative;

                .textarea{
                    &::before{
                        height: calc(100% - 36px);
                    }
                }
                .quill {
                    border-radius: 6px;
                    border: 1px solid #373940 !important;
                    box-shadow: none !important;
                    background: #373940;
                    .ql-toolbar.ql-snow{
                        background: #595959;
                        border-color: #595959;
                    }
                    .ql-snow, .ql-stroke {
                        fill: none;
                        stroke: #FFF !important;
                        &:hover{
                            stroke: #FFF !important;
                        }
                    }
                    .ql-container.ql-snow {
                        height: 120px;
                        border: 1px solid transparent;
                        
                        &::placeholder{
                            color: #FFF !important;
                        }
                        .ql-editor{
                            &::before {
                                color: #FFF !important;
                                font-size: 16px !important;
                                font-family: var(--FontDefault) !important;
                                font-style: normal;
                            }
                            p{
                                color: #FFF !important;
                                font-size: 16px !important;
                                font-family: var(--FontDefault) !important;
                            }
                            ul{
                                margin: 0;
                                padding: 0;
                                li{
                                    color: #FFF !important;
                                    font-size: 16px;
                                    margin-bottom: 4px;
                                }
                            }
                            
                        }
                    }
                }
                .ql-editor{
                    &::before {
                        font-weight: 600;
                        font-size: 14px !important;
                        color: #9B9B9B !important;
                    }
                }
                &::before{
                    display: none;
                    content: "Nossos robôs estão pensando....";
                    padding: 12px 40px;
                    color: #ffffffba;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    height: calc(100% - 8px);
                    width: 50%;
                    border-radius: 5px;
                    background: linear-gradient(90deg, #733BE3 0%, #373940 100%);
                    z-index: 1;
                }
                &::after{
                    display: none;
                    content: "";
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: 7px;
                    left: 8px;
                    background: url('../img/uiw_loading.svg');
                    border-radius: 100px;
                    /* Especifique outras propriedades de fundo conforme necessário */
                    background-size: 18px; /* Defina o tamanho do ícone */
                    background-position: center; /* Centralize o ícone */
                    background-repeat: no-repeat; /* Não repita o ícone */
                    animation: spin 2s linear infinite; /* Adicione a animação de rotação */
                    z-index: 1;
                }

                @keyframes spin {
                    from {
                      transform: rotate(0deg); /* Início da rotação */
                    }
                    to {
                      transform: rotate(360deg); /* Fim da rotação */
                    }
                  }
            }
            .IA {
                background: #743BE4;
                color: #FFF;
                padding: 10px 30px;
                border-radius: 20px;
                box-shadow: none;
                border: none;
                float: right;
                transition: all ease-in 0.2s;
                &:hover{
                    background: #501fb2;
                }
                svg{
                    margin-right: 8px;
                    font-size: 18px;
                    position: relative;
                    top: -3px;
                }
            }

            .Salvar{
                position: relative;
            }

            label {
                width: 100%;
                margin-bottom: 12px;
                position: relative;

                >span {
                    color: #727685;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                    display: inline-block;
                    width: 100%;
                }

                &.LabelAdd {
                    position: relative;
                }
                

                .Fipe {
                    background: url(../img/icon-fipe.png) no-repeat 0 0;
                    padding-left: 25px;
                    position: absolute;
                    font-family: var(--FontSecondary);
                    right: 0;
                    top: 0;
                    color: #FFF;
                    font-size: 12px;
                    font-style: normal;
                    line-height: 22px !important;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: uppercase;

                    strong {
                        color: #8248F3;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        border-left: 1px solid #3D3F47;
                        padding-left: 8px;
                    }
                }

                &.labelInfo {
                    i {
                        color: var(--primaryColor);
                        font-size: 18px;
                        position: relative;
                        top: -2px;
                    }

                    color: var(--whiteColor);
                    font-size: 14px;
                }
            }

            .form-check-input:checked,
            .form-check-input:checked:focus {
                background-color: var(--primaryColor);
                border-color: var(--primaryColor);
            }

            .form-check-label {
                color: #727685;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                display: inline-block;
            }

            input:not([type=checkbox]) {
                border-radius: 6px;
                border: 1px solid #373940 !important;
                box-shadow: none !important;
                background: #373940;
                color: #FFF;
                display: inline-block;
                height: 48px;
            }

            .FormControl {
                width: calc(100% - 59px);
                display: inline-block;
                position: relative;

                &.Full {
                    width: 100%;
                    margin-bottom: 20px;
                }

                i {
                    position: absolute;
                    color: #727685;
                    top: 6px;
                    right: 16px;
                    font-size: 20px;
                }
            }

            textarea {

                &.wrongField {
                    border-left: 7px  var(--dangerColor)  solid !important;
                    border-radius: 5px;
                }

                border-radius: 6px;
                border: 1px solid #373940 !important;
                box-shadow: none !important;
                background: #373940;
                color: #FFF;
                display: inline-block;
                margin-bottom: 20px;
                height: 110px;
                width: 100%;
                padding: 12px 16px;
                resize: none;

                &::placeholder {
                    font-weight: 600;
                    font-size: 14px;
                    color: #9B9B9B !important;
                }
            }

            .Dropdown {
                border-radius: 5px;
                background: #373940;
                border: 1px solid #373940 !important;
                box-shadow: none !important;
                width: 100%;
                height: 48px;
                line-height: 30px;
                padding-left: 10px;

                .p-icon.p-dropdown-trigger-icon.p-clickable {
                    color: #B1B7D1;
                }

                .p-dropdown-trigger {
                    position: relative;
                }

            }

            .p-dropdown {
                span {
                    color: #FFF !important;
                    opacity: 0.6 !important;
                    font-weight: 400 !important;
                }

            }

            .Btns {
                margin: 40px 10px 0 !important;
                display: inline-block;

                &.Add {
                    margin-top: 0px !important;
                }

                &.Remove {
                    margin-top: 0px !important;

                    &.Full {
                        margin-bottom: 20px !important;
                    }
                }

                &.ButtonLight {
                    margin: 20px 0 0 !important;
                }
            }

            .TagsInput {
                span {
                    border-radius: 41.85px;
                    border: 0.93px solid #373940;
                    background: #373940;
                    padding: 5px 10px 5px 18px;
                    color: #727685;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-right: 10px;

                    &:hover {
                        background: linear-gradient(90deg, #894FFC 50%, #7139E1 106.74%);
                        color: #FFF;
                    }

                    i {
                        position: relative;
                        top: -2px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .diferenciais {

                input {
                    margin-bottom: 0;
                }

                textarea {

                    &.wrongField {
                        border-left: 7px var(--dangerColor) solid !important;
                        border-radius: 5px;
                    }
                    
                    height: 80px;
                }
            }

            .nao-cadastrar {
                a {
                    color: #555A67;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration-line: underline;
                }
            }

            .ImagesUploader {
                position: relative;
                display: inline-block;
                margin-right: 20px;
                width: 200px;
                float: left;
                z-index: 1;

                &.Full {
                    width: 100%;

                    .iu-filesInputContainer {
                        width: 100%;
                    }

                    .iu-loadContainer {
                        width: 100%;
                    }
                }

                .iu-label {
                    display: none;
                }

                position: relative;

                .iu-loadContainer {
                    color: inherit;
                    width: 200px;
                    height: 140px;
                    display: inline-block;
                    border-radius: 6px;
                    border: 2px dashed #373940;
                    background: none;

                    .iu-dropzone {
                        border: none !important;
                        border-radius: 6px !important;

                        &:hover {
                            background: rgba(39, 41, 47, 0.2);
                            border-radius: 6px !important;
                        }
                    }

                    span {
                        border-radius: 6px !important;
                        z-index: 2;

                        span {
                            display: none;
                        }
                    }
                }

                >span {
                    width: 100%;
                    height: 136px;
                    position: absolute;
                    top: 2px;
                    left: 0;
                    z-index: -1;
                    text-align: center;
                    background: #191A1E;

                    i {
                        color: #555A67;
                        font-size: 30px;
                        width: 100%;
                        display: inline-block;
                        margin-top: 16px;
                    }

                    p {
                        color: #555A67;
                        font-size: 9px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    h6 {
                        color: #FFF;
                        font-size: 13.02px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                .form-check-label {
                    font-size: 11px;
                    text-transform: initial;
                    letter-spacing: -0.1px;
                    font-weight: 400;
                }

                .iu-filesInputContainer {
                    margin-top: 0;
                }

                .Label {
                    text-align: center
                }
            }
        }
    }
    .protecao{
        padding-top: 30px;
        .switch{
            .Label.form-check.form-switch{
                margin-left: 0;
                .form-check-input{
                    width: 40px;
                    height: 24px;
                    background-color: #5a5c62;
                    border: 3px solid #5a5c62;
                    order: 2;
                   
                }
                label.form-check-label {
                    order: 1;
                    color: var(--whiteColor);
                    margin-left: 10px;
                    margin-top: 10px;
                    color: #727685;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                }
                .form-check-input:checked, .form-check-input:checked:focus  {
                    background-color: var(--primaryColor);
                    border-color: var(--primaryColor);
                }
            }
        }
        table{
            background: #595959;
            width: 100%;
            thead{
                text-align: center;
                tr{
                    th{
                        color: #FFF;
                        font-size: 14px;
                        padding: 20px;

                    }
                }
            }
            tbody{
                background: #373940;
                tr{
                    td{
                        border-right: 1px solid #595959;
                        border-bottom: 1px solid #595959;
                        text-align: center;
                        color: #FFF;
                        font-size: 12px;
                        padding: 10px;
                        .form-check-label{
                            width: auto !important;
                            color: #FFF !important;
                        }
                    }
                    &:last-child{
                        border-left: 1px solid #595959;

                    }
                    &:nth-child(odd){
                        background: #47494f;
                    }
                }
            }
        }

    }
}

@media (max-width: 768px) {
    .Proposta{
        .statusMultiplos{
            top: -110px;
            right: 0px;
        }
        .Novo{
            padding: 40px 20px 40px;
            .HeaderNovo{
                margin-top: 0;
                &.Informacoes{
                    margin-top: 60px;
                }

            }
            .preview-multiple {
                ul {
                    li {
                        .picture {
                            min-width: 98px;
                        }
                    }
                }
            }
            .FormNovo {
                .dropzone.multiple-with-files {
                    span {
                        text-align: center;
                    }
                }
                .ImagesUploader {
                    width: 100%;
                }
            }
        }
        .NavVeiculos{
            margin-top: 60px;
            margin-bottom: -20px;
            ul{
                li{
                    min-width: 56px;
                    span{
                        display: none !important;
                    }
                    a.active{
                        i{
                            width: 52px !important;
                            height: 52px !important;
                            font-size: 20px !important;
                            line-height: 44px !important;
                        }
                    }
                    i{
                        width: 38px !important;
                        height: 38px !important;
                        line-height: 34px !important;
                        font-size: 18px !important;
                    }
                }
                &::before {
                    width: calc(100% - 99px);
                }
            }
        }
    }
}