// CodeConfirm.scss

$primaryColor: #42C4F2;
$secondaryColor: #1D2039;

.page-login{
    .title {
      display: block;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 25px;
      line-height: 38px;

      h4 {
        color: #000000;
      }

      h6 {
        width: 350px;
        color: #8F92A5;
        font-weight: 400;
      }
    }

    .icon {
      position: absolute;
      color: $primaryColor;
      margin: 5px 10px;
    }

    .login {
      padding: 5px;
      background: #FFFFFF;
      border: 1px solid #E1E5FF;
      border-radius: 5px;
      width: 250px;
      margin-bottom: 10px;
      padding-left: 40px;
      background-repeat: no-repeat;
      background-position: 10px center;
      background-size: 20px 20px;
    }

    input::placeholder {
      color: #42496D;
    }

    input:focus {
      outline: none;
    }

    .sendCode {
      cursor: pointer;
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      text-decoration-line: underline;
      color: #7B7B7B;
      margin: 0px 0 0 160px;
      padding-left: 80px;
    }

    .code {
      margin-top: 40px;
    }
  }