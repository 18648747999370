
$dangerColor:#E18B83;
$dangerColorHover:#C61616;
$warningColor:#E3B100;
$publicColor: #1DCC43;
$publicColorHover: #14AD36;

.modal-content{

}

.p-dropdown-panel{
    z-index: 999999 !important;
}
.popup{
    .dropdownWrongField{
        border-radius: 5px;
    }
    .wrongField{
        border-left: 7px var(--dangerColor) solid !important;
    }

    label{
        width: 100%;
        margin-bottom: 6px;
        > span{
            color: #727685;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            display: inline-block;
            width: 100%;
        }
    }
    .form-check-input:checked, .form-check-input:checked:focus  {
        background-color: var(--primaryColor);
        border-color: var(--primaryColor);
    }
    .form-check-label{
        color: #727685;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        display: inline-block;
    }
}
