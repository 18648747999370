
$dangerColor:#E18B83;
$dangerColorHover:#C61616;
$warningColor:#E3B100;
$publicColor: #1DCC43;
$publicColorHover: #14AD36;


.modal-content{

}

.dropdownWrongField{
    border-radius: 5px;
}
.wrongField{
    border-left: 7px var(--dangerColor) solid !important;
}