// SearceUsuarios.scss

.filterSearce{
    position: relative;
    padding: 30px 0;
    input{
        border-radius: 43px;
        border: 1px solid #373940;
        background: #232428;
        padding: 7px 20px;
        font-size: 14px;
        width: 260px;
        &:hover, &:focus{
            border: 1px solid var(--primaryColor);
            outline: none !important;
            border-color: var(--primaryColor) !important;
        }
    }
    i{
        background: var(--primaryColor);
        border-radius: 40px;
        padding: 4px 6px;
        line-height: 20px;
        top: 12px !important;
        right: 5px !important;
        svg{
            color: #FFF;
        }
    }
}
.actionsUsuarios{
    text-align: right;
}