@media (max-width: 768px) {
    .listEstetica{
        padding: 0 !important;
    }
    .UsuariosList {
        .card{
            padding: 20px 17px 0;
        }
        thead{
            display: none;
        }
        .p-datatable-tbody {

            display: inline-block !important;
            .status-column{
                width: 46px   !important;
                display: inline-block !important;
                float: left;
                order: 1;
                padding: 7px 10px;
                strong{
                    display: none;
                }
                span {
                    padding: 5px 5px 6px 9px !important;
                    text-align: center;
                }
            }
            .status-categoria{
                position: absolute;
                top: 0px;
                left: 32px;
                strong{
                    display: none;
                }
                span{
                    padding: 5px 5px 6px 9px !important;
                    
                }
            }
            .title-column {
                width: auto;
                float: left;
                display: inline-block;
                padding: 1rem 4px !important;
            }
            .status-categoria {
                order: 2;
                width: 100px;
                float: left;
            }
            .actions-column {
                border-top: 1px solid #303033 !important;
                margin: auto;
                width: 100% !important;
                display: inline-block;
                padding: 10px !important;
                .View{
                    strong{
                        display: none;
                    }
                }
            }
            .name-column {
                width: 100% !important;
                display: inline-block;
                float: left;
                padding: 6px 14px !important;
                &::before{
                    content: "Nome: ";
                    color: #7d7d7d;
                }
            }
            .category-column {
                width: 100% !important;
                display: inline-block;
                float: left;
                padding: 6px 14px !important;
                &::before{
                    content: "Categoria: ";
                    color: #7d7d7d;
                }
            }
            .model-column{
                width: 100% !important;
                display: inline-block;
                float: left;
                padding: 6px 14px !important;
                &::before{
                    content: "Modelo: ";
                    color: #7d7d7d;
                }
            }
            .mail-column{
                width: 100% !important;
                display: inline-block !important;
                padding: 6px 14px 14px !important;
                &::before{
                    content: "E-mail: ";
                    color: #7d7d7d;
                }
            }
            .hour-column, .data-column, .code-column{
                padding: 12px 3px !important;
                width: auto !important;
            }
            > tr{
                display: inline-block;
                margin-bottom: 10px;
                > td{
                    padding: 7px 9px !important;
                }
            }
        }
        .status-aguardando, .status-aprovada,  .status-pendente,  .status-reprovada, .status-corrigir  {
            position: absolute;
            top: 0;
            left: 0;
            padding: 5px 10px 2px;
            border-radius: 4px;
            font-size: 10px;
            margin-left: 4px;
            .status{
                font-size: 11px !important;
            }
        }
        .status-aguardando{
            background: rgba(66,196, 242, 0.3);
            color: #27AFE2;
        }
        .status-aprovada{
            background: rgba(108,201, 86, 0.3);
            color: #88A71B;
        }
        .status-pendente{
            background: rgba(39,220, 224, 0.3);
            color: var(--lightColorHover);
        }
        .status-reprovada{
            background: rgba(218,66, 66, 0.3);
            color: var(--dangerColor);
        }
        .status-corrigir{
            background: rgba(255,138, 0, 0.3);
            color: #FF8A00;
        }
    }
}